import { useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import urls from 'urls';
import * as Yup from 'yup';

import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import Session from 'components/shared/Session';
import { NEXT_STEP } from 'constants/index';
import { mfaLogin } from 'services/auth';

const Schema = Yup.object().shape({
  mfaCode: Yup.string().required(i18n.t('validation.required')),
});

export const VerifyMfa = (): JSX.Element => {
  const { t } = useTranslation();
  const { state, search } = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (!state) {
      history.push({ pathname: urls.login, search });
    }
  }, [history, search, state]);

  return (
    <Session className="phone-number-form h-90vh">
      <Formik
        enableReinitialize
        initialValues={{ mfaCode: '' }}
        validationSchema={Schema}
        onSubmit={({ mfaCode }, { setSubmitting, setFieldError }) => {
          const body = {
            userId: state.userId,
            mfaCode,
            session: state.session,
          };
          const queryString = new URLSearchParams(search);

          const params = {
            redirectUri: queryString.get('redirectUri'),
            clientId: queryString.get('clientId'),
          };

          setSubmitting(true);
          mfaLogin(body, params)
            .then((res) => {
              if (res.data.body.flow.nextStep === NEXT_STEP.REDIRECT) {
                window.location.href = res.data.body.flow.details.redirectUri;
              } else if (res.data.body.flow.nextStep === NEXT_STEP.ADD_ORG) {
                history.push(urls.addOrganization.concat(search));
              } else if (res.data.body.flow.nextStep === NEXT_STEP.LOGIN) {
                history.push(urls.login.concat(search));
              } else if (res.data.body.flow.nextStep === NEXT_STEP.ACCOUNT_DEACTIVATED) {
                history.push(urls.accountDeactivated);
              }
              setSubmitting(false);
            })
            .catch((error) => {
              setFieldError('mfaCode', t('text.InvalidVerificationCode'));
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <div className="user-form-inner">
              <div className="head">
                <h2>{t('text.VerifyAccount')}</h2>
              </div>
              <div className="instructions">
                <p>{t('text.AddMfaCode')}</p>
              </div>
              <Form.Group controlId="formBasicVerificationCode" className="text-left">
                <Form.Label>{t('text.VerificationCode')}</Form.Label>
                <Field name="mfaCode" className="form-control" />
                <ErrorMessage name="mfaCode" />
                {process.env.REACT_APP_USE_MFA_BACKUP_CODES === 'true' ? (
                  <p className="info-text">
                    {t('text.UseBackupCode')}
                    <Button variant="link">{t('buttons.BackupCode')}</Button>
                  </p>
                ) : null}
              </Form.Group>
              <KudoButton variant="primary" className="btn-block" type="submit">
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  t('buttons.Continue')
                )}
              </KudoButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Session>
  );
};
