import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const Dropdown = ({ name, options, className, initialOption }) => (
  <Field as="select" name={name} className={className}>
    {initialOption && <option>{`${initialOption}`}</option>}
    {options.map((option) => (
      <option key={option.value || option} value={option.value || option}>
        {option.name || option}
      </option>
    ))}
  </Field>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  initialOption: PropTypes.string,
  className: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })])
  ).isRequired,
};

Dropdown.defaultProps = {
  initialOption: '',
};

export default Dropdown;
