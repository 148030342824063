import React from 'react';

const RenderPassword = () => (
  <>
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
    <i className="fas fa-ellipsis-h" />
  </>
);

export default RenderPassword;
