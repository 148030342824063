import React from 'react';
import PropTypes from 'prop-types';

const ProfilePicture = ({ name, profilePic }) => {
  const initials = name?.split(' ');

  return (
    <>
      {profilePic ? (
        <img className="profile-image" src={profilePic} alt="dp" />
      ) : (
        <span className="name-initials">{name && <>{initials[0] && initials[0][0]}</>}</span>
      )}
    </>
  );
};

ProfilePicture.propTypes = {
  name: PropTypes.string,
  profilePic: PropTypes.string,
};

ProfilePicture.defaultProps = {
  name: '',
  profilePic: '',
};
export default ProfilePicture;
