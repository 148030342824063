import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import withClearCache from 'ClearCache';
import urls from 'urls';

import { AcceptOrganizationInvitation } from 'components/acceptOrganizationInvitation';
import { OrganizationDeactivated } from 'components/acceptOrganizationInvitation/OrganizationDeactivated';
import AccountDeleted from 'components/deleteAccount/AccountDeleted';
import DeleteAccount from 'components/deleteAccount/DeleteAccount';
import { LoginForm as MagicLinkLogin } from 'components/login/magicLink/LoginForm';
import { MagicLinkVerification } from 'components/login/magicLink/MagicLinkVerification';
import { SignupForm as MagicLinkSignup } from 'components/signup/magicLink/SignupForm';
import { CheckInbox } from 'containers/CheckInbox';
import { ForgotPassword } from 'containers/ForgotPassword';
import Login from 'containers/Login';
import { MfaVerification } from 'containers/MfaVerification';
import SetPassword from 'containers/SetPassword';
import Signup from 'containers/Signup';
import { AccountDeactivated } from 'pages/AccountDeactivated';
import { LinkExpired } from 'pages/LinkExpired';
import { MagicLinkCheckInbox } from 'pages/MagicLinkCheckInbox';
import { AuthenticatedRoutes } from 'routes/AuthenticatedRoutes';
import { selectUserId } from 'selectors/user';
import { getFeatureFlags } from 'shared/utils/featureFlags';

import 'App.scss';
import 'stylesheets/form.scss';
import 'stylesheets/footer.scss';
import 'stylesheets/header.scss';
import 'stylesheets/profile.scss';
import 'stylesheets/modal.scss';

export const App = (): JSX.Element => <ClearCacheComponent />;

const MainApp = (): JSX.Element => {
  const userId = useSelector(selectUserId);
  const isMagicLinkEnabledForLogin = getFeatureFlags().WithLoginMagicLink;
  const isMagicLinkEnabledForSignUp = getFeatureFlags().WithSignUpMagicLink;
  const isMultiUserOrgEnabled = getFeatureFlags().WithMultiUserOrganizations;
  const isDeleteUserEnabled = getFeatureFlags().WithDeleteUser;

  return (
    <>
      <ToastContainer />
      <Switch>
        <Route exact path={urls.login} component={isMagicLinkEnabledForLogin ? MagicLinkLogin : Login} />
        <Route exact path={urls.forgotPassword} component={ForgotPassword} />
        <Route exact path={urls.signup} component={isMagicLinkEnabledForSignUp ? MagicLinkSignup : Signup} />
        <Route exact path={urls.checkInbox} component={CheckInbox} />
        <Route exact path={urls.magicLinkSignUpCheckInbox} component={MagicLinkCheckInbox} />
        <Route exact path={urls.magicLinkLoginCheckInbox} component={MagicLinkCheckInbox} />
        <Route exact path={urls.setPassword} component={SetPassword} />
        <Route exact path={urls.verifyMfa} component={MfaVerification} />
        <Route exact path={urls.accountDeactivated} component={AccountDeactivated} />
        {isMultiUserOrgEnabled && (
          <Route exact path={urls.organizationDeactivated} component={OrganizationDeactivated} />
        )}
        {isMultiUserOrgEnabled && <Route exact path={urls.invitationAccepted} component={OrganizationDeactivated} />}
        {isMultiUserOrgEnabled && (
          <Route exact path={urls.acceptOrganizationInvitation} component={AcceptOrganizationInvitation} />
        )}
        {isDeleteUserEnabled && <Route exact path={urls.deleteAccount} component={DeleteAccount} />}
        {isDeleteUserEnabled && <Route exact path={urls.accountDeleted} component={AccountDeleted} />}

        {(isMagicLinkEnabledForLogin || isMagicLinkEnabledForSignUp) && (
          <Route exact path={urls.linkVerification} component={MagicLinkVerification} />
        )}
        {(isMagicLinkEnabledForLogin || isMagicLinkEnabledForSignUp) && (
          <Route exact path={urls.linkExpired} component={LinkExpired} />
        )}
        {userId ? <AuthenticatedRoutes /> : <Redirect to={urls.login} />}
      </Switch>
    </>
  );
};

const ClearCacheComponent = withClearCache(MainApp);
