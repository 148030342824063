import { filterMemberData } from 'helpers/authHelpers';

import * as constants from '../constants/authConstants';

const initialState = {
  userId: null,
  clientId: null,
  profile: {},
  isAuthenticating: false,
  redirectUri: null,
  organization: {},
  members: [],
  organizations: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case constants.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case constants.SET_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.payload,
      };
    case constants.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case constants.SET_MEMBERS:
      return {
        ...state,
        members: filterMemberData(action.payload),
      };
    case constants.REMOVE_MEMBER: {
      return {
        ...state,
        members: state.members.filter((member) => member.invitationId !== action.payload),
      };
    }
    case constants.SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case constants.UPDATE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.map((organization) =>
          organization.id === action.payload.id ? { ...organization, ...action.payload } : organization
        ),
      };
    case constants.SIGNOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
