/* eslint react-hooks/exhaustive-deps: "error"  */

import React, { useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import KudoButton from 'components/elements/facelift/Button';
import { reArrangeAvailableRegions } from 'helpers/authHelpers';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { changeRegion, getAvailableRegions } from 'services/auth';

const UpdateNameModal = ({ show, onHide, currentRegion, setRegion }) => {
  const { t } = useTranslation();
  const [newRegion, setNewRegion] = useState(currentRegion);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      getAvailableRegions().then((res) => {
        if (res.data.body) {
          const apiRegions = reArrangeAvailableRegions(res.data.body);

          setAvailableRegions(apiRegions);
        }
      });
    }
  }, [show]);

  const updateRegion = useCallback(() => {
    setIsSubmitting(true);
    changeRegion({ region: newRegion })
      .then(() => {
        const updatedRegion = availableRegions.find((reg) => reg.value === newRegion);

        if (updatedRegion.name === 'European Union (default)') {
          updatedRegion.name = 'European Union';
        }
        setRegion(updatedRegion);
        showNotification(t('text.DataResidencyRegionUpdatedMessage'), 'success');
        onHide();
      })
      .catch((errors) => {
        showAllNotifications(parseResponseErrors(errors), 'error');
      })
      .finally(() => setIsSubmitting(false));
  }, [newRegion, onHide, setRegion, availableRegions, t]);

  const handleOnHide = useCallback(() => {
    setNewRegion(currentRegion);
    onHide();
  }, [currentRegion, onHide]);

  return (
    <Modal show={show} onHide={handleOnHide} id="modal" centered>
      <Modal.Header closeButton className="border-0 pr-4 pl-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{t('text.ChangeDataResidencyRegion')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pr-4 pl-4">
        <Form.Group className="icon-show-holder">
          <Form.Label htmlFor="region-select">{t('text.DataResidencyRegion')}</Form.Label>
          <select
            value={newRegion}
            onChange={(e) => setNewRegion(e.target.value)}
            className="form-control"
            id="region-select"
          >
            {availableRegions.map((region) => (
              <option key={region.value} value={region.value}>
                {region.name}
              </option>
            ))}
          </select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {isSubmitting ? (
          <KudoButton variant="primary">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </KudoButton>
        ) : (
          <KudoButton onClick={updateRegion}>{t('buttons.Save')}</KudoButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

UpdateNameModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentRegion: PropTypes.string.isRequired,
  setRegion: PropTypes.func.isRequired,
};

export default UpdateNameModal;
