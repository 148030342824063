import { ForgotPassword as ForgotPass } from 'components/forgotPassword/ForgotPassword';

import { HeapAnalytics } from '../components/shared/HeapAnalytics';

export const ForgotPassword = (): JSX.Element => (
  <>
    <HeapAnalytics />
    <ForgotPass />
  </>
);
