import axios from 'axios';
import { createAction } from 'redux-actions';
import URLs from 'urls';

import { SIGNOUT_SUCCESS } from '../redux/constants/authConstants';
import factory from '../redux/store';

export const SignOutSuccess = createAction(SIGNOUT_SUCCESS);

export const defaultHeader = () => {
  // default headers goes here (PS. receive config in parameter)
  // config.headers.common['HTTP-X-API-TOKEN'] = process.env.REACT_APP_HTTP_X_API_TOKEN;
};

const { store } = factory();

const UNAUTHORIZED_ERROR_CODE = 401;

const checkIfSessionExpired = ({ response: { config: { url }, status } = {} }) => {
  if (url !== URLs.login && !url.includes('/session') && status === UNAUTHORIZED_ERROR_CODE) {
    store.dispatch(SignOutSuccess());
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
};

const baseService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    (config) => {
      defaultHeader(config);
      config.withCredentials = true;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      checkIfSessionExpired(err);

      return Promise.reject(err);
    }
  );

  return instance;
};

export default baseService();
