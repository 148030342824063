import { capitalize } from 'lodash';
import URLs from 'urls';

import { ROLES } from 'constants/index';

export const generateLoginUrl = (clientId, redirectUri) => {
  const url = `/login?${new URLSearchParams({ clientId, redirectUri }).toString()}`;

  return decodeURIComponent(url);
};

/**
 * Generates magic link login URL.
 * @param {URLSearchParams} searchParams URL search params to pass down to the login link.
 */
export const generateMagicLinkLoginUrl = (searchParams) => `${URLs.login}?${searchParams.toString()}`;

export const reArrangeAvailableRegions = (body) => {
  const { availableRegions: apiRegions } = body;
  const defaultIndex = apiRegions.findIndex((region) => region.name === 'European Union');

  if (defaultIndex !== -1) {
    apiRegions[defaultIndex].name = apiRegions[defaultIndex].name.concat(' (default)');
    if (defaultIndex > 0) {
      const temp = apiRegions[defaultIndex];

      // eslint-disable-next-line prefer-destructuring
      apiRegions[defaultIndex] = apiRegions[0];
      apiRegions[0] = temp;
    }
  }

  return apiRegions;
};

export const shouldShowOrganizationTab = (userRoles) =>
  userRoles && (userRoles.includes(ROLES.CLIENT_ADMIN) || userRoles.includes(ROLES.CLIENT));

export const filterMemberData = (members) =>
  members.map((member) => ({
    userId: member.userId,
    status: member.status,
    invitationId: member.invitationId,
    name:
      member.status === 'INVITED'
        ? `Pending - ${capitalize(member.status)} ${new Date(member.dateInvited)
            .toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: '2-digit',
            })
            .replace(',', ' ')}`
        : member.name || '',
    email: member.email,
    role: member.role.includes('org_admin') ? 'Admin' : 'Member',
  }));
