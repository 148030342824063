import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { inviteOrganizationUsers } from 'redux/actions/authActions';
import URLs from 'urls';

import KudoButton from 'components/elements/facelift/Button';
import HelpTooltip from 'components/profile/HelpTooltip';
import { Footer } from 'components/shared/Footer';
import { useCurrentOrganizationId } from 'hooks/useCurrentOrganizationId';

import { InviteFailureModal } from './InviteFailureModal';
import { emailSeparatorRegex, MemberStatus } from './InviteOrganizationUsers.constants';

import styles from './InviteOrganizationUsers.module.scss';

const InviteOrganizationUsers = (): JSX.Element => {
  const { t } = useTranslation();
  const [memberStatus, setMemberStatus] = useState(MemberStatus.MEMBER);
  const [emails, setEmails] = useState<string>('');
  const [nonEmptyEmails, setNonEmptyEmails] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const organizationId = useCurrentOrganizationId();
  const history = useHistory();
  const [shouldShowFailureModal, setShouldShowFailureModal] = useState(false);
  const [failedInvites, setFailedInvites] = useState<string[]>([]);
  const [shouldRedirectToOrg, setShouldRedirectToOrg] = useState<boolean>(false);

  useEffect(() => {
    setNonEmptyEmails(emails.split(emailSeparatorRegex).filter((email) => email !== ''));
  }, [emails]);

  const handleMemberStatusChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMemberStatus(event.target.value as MemberStatus);
  };

  if (!organizationId) {
    return <></>;
  }

  const redirectToOrganization = (): void => {
    history.push(URLs.generateOrganizationUrl(organizationId));
  };

  const submitForm = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await inviteOrganizationUsers(
        organizationId,
        {
          roles: [memberStatus],
          emails: nonEmptyEmails,
        },
        setIsSubmitting,
        redirectToOrganization,
        setFailedInvites,
        setShouldShowFailureModal,
        setShouldRedirectToOrg
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const Help = (): JSX.Element => (
    <div className="text-left">
      <p>{t('inviteOrganizationUsers.tooltip-first')}</p>
      <p>{t('inviteOrganizationUsers.tooltip-second')}</p>
    </div>
  );

  return (
    <div className={styles.Wrapper}>
      <InviteFailureModal
        shouldShow={shouldShowFailureModal}
        onHide={() => setShouldShowFailureModal(false)}
        failedInvites={failedInvites}
        redirectToOrg={shouldRedirectToOrg ? redirectToOrganization : undefined}
      />
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <h1 className="h2 text-center mt-5">{t('inviteOrganizationUsers.title')}</h1>
            <h2 className="h5 text-center">{t('inviteOrganizationUsers.subtitle')}</h2>
            <Form>
              <Card className="w-100 p-5 mt-5">
                <Form.Group>
                  <Form.Label className="mb-3">{t('inviteOrganizationUsers.radio.label')}</Form.Label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="statusMemberRadio"
                      value={MemberStatus.MEMBER}
                      onChange={handleMemberStatusChange}
                      checked={memberStatus === MemberStatus.MEMBER}
                    />
                    <label className="form-check-label" htmlFor="statusMemberRadio">
                      <p className="mb-1">
                        <strong>{t('inviteOrganizationUsers.radio.member.label')}</strong>
                      </p>
                      <p>{t('inviteOrganizationUsers.radio.member.description')}</p>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="statusAdminRadio"
                      value={MemberStatus.ADMIN}
                      onChange={handleMemberStatusChange}
                      checked={memberStatus === MemberStatus.ADMIN}
                    />
                    <label className="form-check-label" htmlFor="statusAdminRadio">
                      <p className="mb-1">
                        <strong>{t('inviteOrganizationUsers.radio.admin.label')}</strong>
                      </p>
                      <p className="mt-0">{t('inviteOrganizationUsers.radio.admin.description')}</p>
                    </label>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="mr-2">{t('inviteOrganizationUsers.textarea.label')}</Form.Label>
                  <HelpTooltip text={<Help />} tooltipClass={styles.helpTooltip} />
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={emails}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setEmails(event.target.value)}
                    placeholder={`e.g. \njohn.doe@email.com\njane.doe@gmail.com`}
                  />
                </Form.Group>
                <KudoButton
                  type="submit"
                  variant="primary"
                  className="cancelbutton"
                  disabled={isSubmitting || nonEmptyEmails.length === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  {isSubmitting ? <Spinner size="sm" animation="border" /> : t('inviteOrganizationUsers.button.submit')}
                </KudoButton>
                <Button
                  disabled={isSubmitting}
                  variant="outline-secondary"
                  className="cancelbutton"
                  as={Link}
                  to={URLs.generateOrganizationUrl(organizationId)}
                >
                  {t('inviteOrganizationUsers.button.cancel')}
                </Button>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>

      <div className={styles.Footer}>
        <Footer />
      </div>
    </div>
  );
};

export { InviteOrganizationUsers };
