import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import emailCheck from 'assets/images/email-icon.png';
import { Footer } from 'components/shared/Footer';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';

export const CheckInbox = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="user-form checkinbox">
      <HeapAnalytics />
      <Container className="body-container">
        <Row>
          <Col md="12">
            <a className="logoform" href="/">
              {t('headings.Kudo')}
            </a>
            <Form className="user-form-inner">
              <h2 className="mb-5 h3">{t('checkinbox.checkYourinbox')}</h2>
              <div className="email-icon-holder">
                <img src={emailCheck} alt="check-email" />
              </div>
              <div className="head">
                <h2>{t('checkinbox.checkYourinbox')}</h2>
                <p>{t('checkinbox.verifyEmail')}</p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
