/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import urls from 'urls';

import KudoButton from 'components/elements/facelift/Button';
import Session from 'components/shared/Session';
import { NEXT_STEP } from 'constants/index';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification from 'helpers/showNotification';
import { resendVerificationCode, verifyPhoneNumber } from 'services/auth';

const VerifyPhoneNumber = ({ userId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resendCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      verificationCode,
      userId,
    };
    const params = {
      clientId: new URLSearchParams(search).get('clientId'),
      redirectUri: new URLSearchParams(search).get('redirectUri'),
    };

    setIsSubmitting(true);
    verifyPhoneNumber(body, params)
      .then((response) => {
        if (response.data.body.flow.nextStep === NEXT_STEP.REDIRECT) {
          window.location.href = response.data.body.flow.details.redirectUri;
        } else if (response.data.body.flow.nextStep === NEXT_STEP.ADD_ORG) {
          history.push(urls.addOrganization.concat(search));
        } else if (response.data.body.flow.nextStep === NEXT_STEP.LOGIN) {
          history.push(urls.login.concat(search));
        }
        setErrors([]);
        setIsSubmitting(false);
      })
      .catch((error) => {
        setErrors(parseResponseErrors(error));
        setIsSubmitting(false);
      });
  };

  const handleResendCode = () => {
    resendVerificationCode().then(() => {
      showNotification(t('text.NewVerificationCodeSent'), 'success');
    });
  };

  return (
    <>
      <Session className="phone-number-form">
        <Form onSubmit={handleSubmit} className="user-form-inner">
          <div className="head">
            <h2>{t('text.VerifyPhoneNumber')}</h2>
          </div>
          <div className="instructions">
            <p>{t('text.AddVerificationCode')}</p>
          </div>
          <Form.Group controlId="formBasicVerificationCode">
            <Form.Label>{t('text.VerificationCode')}</Form.Label>
            <Form.Control
              type="text"
              name="verificationCode"
              className="form-control"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
            {errors.map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index} className="text-danger text-left phone-num-validate mb-0">
                {error}
              </p>
            ))}
          </Form.Group>
          {isSubmitting ? (
            <KudoButton variant="primary">
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            </KudoButton>
          ) : (
            <KudoButton type="submit">{t('buttons.Verify')}</KudoButton>
          )}
          {resendCode ? (
            <div className="login-caption">
              <p className="mb-1">{t('text.DidNotGetCode')}</p>
              <p className="mb-4">{t('text.CodeRequest')}</p>
              <p>
                <Link to={urls.login}>{t('buttons.UseDifferentNumber')}</Link>
              </p>
            </div>
          ) : (
            <div className="login-caption">
              <p className="mb-4">
                <Button variant="link" onClick={handleResendCode}>
                  {t('buttons.ResendCode')}
                </Button>
              </p>
              <p>
                <Link to={urls.addPhoneNumber.concat(search)}>{t('buttons.ChangePhoneNumber')}</Link>
              </p>
            </div>
          )}
        </Form>
      </Session>
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.authReducer.userId,
});

VerifyPhoneNumber.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(VerifyPhoneNumber);
