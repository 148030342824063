import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import urls from 'urls';

import { NEXT_STEP } from 'constants/index';
import { LoginResponse } from 'shared/types/loginResponse';

export const usePostLoginNextStep = (): { handleNextStep: (data: LoginResponse) => void } => {
  const { search } = useLocation();
  const history = useHistory();

  const handleNextStep = useCallback(
    (data) => {
      switch (data.flow.nextStep) {
        case NEXT_STEP.ADD_PHONE:
          history.push(urls.addPhoneNumber.concat(search));
          break;
        case NEXT_STEP.REDIRECT:
          window.location.href = data.flow.details.redirectUri;
          break;
        case NEXT_STEP.ADD_ORG:
          history.push(urls.addOrganization.concat(search));
          break;
        case NEXT_STEP.MAGIC_LINK_EXPIRED:
          history.push(
            urls.linkExpired.concat(
              `${search}&email=${encodeURIComponent(data.flow.details.email)}&isMagicLinkEnabled=${
                data.flow.details.magicLinkEnabled
              }`
            )
          );
          break;
        case NEXT_STEP.SMS_MFA:
          history.push({
            pathname: urls.verifyMfa,
            state: { userId: data.userId, session: data.flow.details.session },
            search,
          });
          break;
        case NEXT_STEP.ACCOUNT_DEACTIVATED:
          history.push(urls.accountDeactivated);
          break;
      }
    },
    [history, search]
  );

  return { handleNextStep };
};
