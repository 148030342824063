import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from 'components/elements/facelift/Button';
import TermsAndConditions from 'constants/TermsAndConditions';

import styles from './Popup.module.scss';

interface Props {
  onClose: () => void;
}

export const Popup = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.modalContainer}>
      <h2 className={classNames(styles.modalHeading, 'mb-4')}>{t('termsAndConditions')}</h2>

      <div className={styles.scrollBox}>
        <TermsAndConditions />
      </div>
      <div className={styles.btnContainer}>
        <Button className="btn-block" onClick={onClose}>
          {t('buttons.agree')}
        </Button>
      </div>
    </div>
  );
};
