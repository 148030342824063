import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import styles from './HeaderWithoutUser.module.scss';

export const HeaderWithoutUser = (): JSX.Element => (
  <header id="header">
    <Container fluid className={styles.maxWidth}>
      <Navbar expand="lg">
        <Navbar.Brand href="#">Kudo</Navbar.Brand>
      </Navbar>
    </Container>
  </header>
);
