import { useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { verifyMagicLink } from 'redux/actions/authActions';
import urls from 'urls';

import { usePostLoginNextStep } from 'hooks/usePostLoginNextStep';

import styles from './MagicLinkVerification.module.scss';

export const MagicLinkVerification = (): JSX.Element => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { handleNextStep } = usePostLoginNextStep();

  const redirectToLogin = useCallback(() => {
    history.push(urls.login);
  }, [history]);

  useEffect(() => {
    const query = new URLSearchParams(search);

    const payload = {
      clientId: query.get('clientId'),
      redirectUri: query.get('redirectUri'),
      challenge: query.get('challenge'),
      session: query.get('session'),
    };

    dispatch(verifyMagicLink(payload, handleNextStep, redirectToLogin));
  }, [dispatch, handleNextStep, redirectToLogin, search]);

  return (
    <Container className={styles.container}>
      <h1 className={styles.title}>{t('verification.title.Authenticating')}</h1>
      <Skeleton height={50} count={5} className="mb-2" />
    </Container>
  );
};
