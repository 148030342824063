import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';

interface EmailChangeHandlerProps {
  onChange?(newEmail: string, oldEmail: string): void;
  defaultEmail?: string | null;
}
export const EmailChangeHandler = ({ onChange, defaultEmail }: EmailChangeHandlerProps): null => {
  const { values, setFieldValue, submitForm } = useFormikContext<{ email: string }>();
  const [hasSubmittedWithDefaultEmail, setHasSubmittedWithDefaultEmail] = useState(false);

  const emailRef = useRef<string>();

  useEffect(() => {
    if (defaultEmail && !hasSubmittedWithDefaultEmail) {
      setHasSubmittedWithDefaultEmail(true);
      setFieldValue('email', defaultEmail);
      submitForm();
    }
  }, [defaultEmail, hasSubmittedWithDefaultEmail, setFieldValue, submitForm]);

  useEffect(() => {
    if (emailRef.current === values.email) {
      return;
    }

    onChange?.(values.email, emailRef.current as string);

    emailRef.current = values.email;
  }, [onChange, values]);

  return null;
};
