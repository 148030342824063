import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import { SetProfile } from 'redux/actions/authActions';
import { SIGNOUT_SUCCESS } from 'redux/constants/authConstants';
import { createAction } from 'redux-actions';
import URLs from 'urls';
import * as Yup from 'yup';

import { Button } from 'components/elements/Button';
import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { setPassword } from 'services/auth';
import { RootState } from 'shared/types/store';

import styles from './ResetPasswordModal.module.scss';

export const SignOutSuccess = createAction(SIGNOUT_SUCCESS);

const Schema = Yup.object().shape({
  confirmationCode: Yup.string().required(i18n.t('validation.required')),
  newPassword: Yup.string().min(8, i18n.t('validation.min8Characters')).required(i18n.t('validation.required')),
  retypedNewPassword: Yup.string()
    .required(i18n.t('validation.required'))
    .when('newPassword', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('newPassword')], i18n.t('validation.passwordNotMatched')),
    }),
});

interface Props {
  shouldShow: boolean;
  onHide: () => void;
}

export const ResetPasswordModal = ({ shouldShow, onHide }: Props): JSX.Element => {
  const { clientId, redirectUri } = useSelector(({ authReducer }: RootState) => authReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [shouldShowNewPassword, setShouldShowNewPassword] = useState(false);
  const [showRetypedPassword, setShowRetypedPassword] = useState(false);

  const { profile } = useSelector(({ authReducer }: RootState) => authReducer);

  const handleOnHide = (): void => {
    setShouldShowNewPassword(false);
    setShowRetypedPassword(false);
    onHide();
  };

  const initialValues = {
    confirmationCode: '',
    newPassword: '',
    retypedNewPassword: '',
  };

  return (
    <Modal show={shouldShow} onHide={handleOnHide} id="modal" centered className="reset-password-modal">
      <Modal.Header closeButton className="border-0 pr-4 pl-4 pb-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{profile.isPasswordUpdated ? t('text.ChangePassword') : t('text.SetPassword')}</b>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={({ confirmationCode, newPassword, retypedNewPassword }, { setSubmitting }) => {
          setPassword({
            email: profile.email,
            confirmationCode,
            password: newPassword,
            retypedPassword: retypedNewPassword,
            userName: profile.fullName,
          })
            .then(() => {
              showNotification(t('text.PasswordUpdatedMessage'), 'success');
              if (profile.isPasswordUpdated) {
                dispatch(SignOutSuccess());
                history.push(`${URLs.login}?clientId=${clientId}&redirectUri=${redirectUri}`);
              } else {
                dispatch(
                  SetProfile({
                    ...profile,
                    isPasswordUpdated: true,
                  })
                );
              }
              handleOnHide();
            })
            .catch((err) => {
              setSubmitting(false);
              showAllNotifications(parseResponseErrors(err), 'error');
            });
        }}
      >
        {() => (
          <FormikForm>
            <Modal.Body className="pr-4 pl-4">
              <p className={styles.verificationText}>{t('resetPassword.enterTheVerificationCode')}</p>
              <Form.Group controlId="formBasicEmailVerificationCode" className="icon-show-holder">
                <Form.Label id="email-verification-code-label">{t('resetPassword.emailVerificationCode')}</Form.Label>
                <InputGroup>
                  <Field
                    name="confirmationCode"
                    className="form-control no-border-right no-box-shadow"
                    aria-labelledby="email-verification-code-label"
                  />
                </InputGroup>
                <ErrorMessage name="confirmationCode" />
              </Form.Group>
              <Form.Group controlId="formBasicNewPassword" className="icon-show-holder">
                <Form.Label id="new-password-label">{t('text.NewPassword')}</Form.Label>
                <InputGroup>
                  <Field
                    type={shouldShowNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    className="form-control no-border-right no-box-shadow"
                    aria-labelledby="new-password-label"
                  />
                  <Button
                    className="show-link"
                    onClick={() => setShouldShowNewPassword(!shouldShowNewPassword)}
                    variant="link"
                    text={shouldShowNewPassword ? t('password.buttons.hide') : t('password.buttons.show')}
                  />
                </InputGroup>
                <ErrorMessage name="newPassword" />
              </Form.Group>
              <Form.Group controlId="formBasicRetypedPassword" className="icon-show-holder">
                <Form.Label id="new-retyped-password-label">{t('text.ConfirmNewPassword')}</Form.Label>
                <InputGroup>
                  <Field
                    type={showRetypedPassword ? 'text' : 'password'}
                    name="retypedNewPassword"
                    className="form-control no-border-right no-box-shadow"
                    aria-labelledby="new-retyped-password-label"
                  />
                  <Button
                    className="show-link"
                    onClick={() => setShowRetypedPassword(!showRetypedPassword)}
                    variant="link"
                    text={showRetypedPassword ? t('password.buttons.hide') : t('password.buttons.show')}
                  />
                </InputGroup>
                <ErrorMessage name="retypedNewPassword" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <KudoButton type="submit">{t('buttons.Save')}</KudoButton>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
