export const regions = [
  {
    name: 'European Union',
    value: 'eu-west-1',
  },
  {
    name: 'United States',
    value: 'us-east-1',
  },
  {
    name: 'Canada',
    value: 'ca-central-1',
  },
];

export const NEXT_STEP = {
  ADD_PHONE: 'ADD_PHONE',
  REDIRECT: 'REDIRECT',
  LOGIN: 'LOGIN',
  SMS_MFA: 'SMS_MFA',
  ADD_ORG: 'ADD_ORG',
  ACCOUNT_DEACTIVATED: 'ACCOUNT_DEACTIVATED',
  SHOW_PASSWORD: 'SHOW_PASSWORD',
  CHECK_INBOX: 'CHECK_INBOX',
  MAGIC_LINK_EXPIRED: 'MAGIC_LINK_EXPIRED',
};

export const PROFILE_TAB = {
  ACCOUNT: 'ACCOUNT',
  ORGANIZATION: 'ORGANIZATION',
};

export const ROLES = {
  SUPER_ADMIN: 'super_admin',
  CLIENT: 'client',
  CLIENT_ADMIN: 'client_admin',
  ACCOUNT_REP: 'account_rep',
  FINANCE_OPS: 'finance_ops',
  INTERPRETER: 'interpreter',
};
