import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authorizeRole } from 'redux/actions/authActions';

import { Profile } from 'components/profile/Profile';

export const BaseProfile = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const updateUrl = useCallback(() => history.push(history.location.pathname), [history]);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');

    if (code !== null) {
      dispatch(authorizeRole({ codeGrant: code }, updateUrl));
    }
  }, [dispatch, updateUrl]);

  return (
    <div className="user-profile">
      <Profile />
    </div>
  );
};
