import React from 'react';

import Users from '../components/users/Users';

const ManageUsers = () => (
  <div className="auth-wrapper">
    <Users />
  </div>
);

export default ManageUsers;
