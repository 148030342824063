import { useMemo } from 'react';

import { Heap } from 'shared/types/heap';

export const useHeapApi = (): Pick<Heap, 'identify' | 'track'> | undefined =>
  // Using heap inside this conditional instead, assuming that the
  // heap object is already loaded when the uniqueIdentifierId param is updated.
  // Context: Initially window.heap is an array, but later
  // when heap script loads, window.heap becomes an object.
  // Followed best practice in the following link to make heap work:
  // https://github.com/segmentio/analytics.js/issues/605#issuecomment-605464507
  // eslint-disable-next-line consistent-return
  useMemo(() => {
    if (window.heap && !Array.isArray(window.heap)) {
      return {
        track,
        identify,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.heap]);

const identify = (uniqueIdentifierId: string): void => {
  if (uniqueIdentifierId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.heap!.identify(uniqueIdentifierId);
  }
};

const track = (event: string, properties: unknown): void => {
  if (window.heap) {
    window.heap.track(event, properties);
  }
};
