import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap';

export type ButtonProps = BootstrapButtonProps & {
  iconClass?: string;
  shouldShowIcon?: boolean;
  text?: string;
};

export const Button = ({
  iconClass = 'fa fa-check pr-10',
  shouldShowIcon = false,
  text = '',
  disabled = false,
  ...props
}: ButtonProps): JSX.Element => (
  <BootstrapButton disabled={disabled} {...props}>
    {shouldShowIcon && <i className={iconClass} />}
    {text}
  </BootstrapButton>
);
