import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import KudoButton from 'components/elements/facelift/Button';
import showNotification from 'helpers/showNotification';

import styles from './InviteFailureModal.module.scss';

interface Props {
  shouldShow: boolean;
  onHide: () => void;
  failedInvites: string[];
  redirectToOrg?: () => void;
}

export const InviteFailureModal = ({ shouldShow, onHide, failedInvites, redirectToOrg }: Props): JSX.Element => {
  const handleOnHide = useCallback(() => {
    onHide();
  }, [onHide]);
  const { t } = useTranslation();

  return (
    <Modal show={shouldShow} onHide={handleOnHide} id="modal" centered>
      <Modal.Header closeButton className="border-0 pr-4 pl-4" />

      <i className={`far fa-frown ${styles.sadFace} mt-2 mb-4`} />
      <div className="mb-4">
        <Modal.Title id="contained-modal-title-vcenter" className={`${styles.Heading} h5`}>
          {t('failedInvitesModalTitle')}
        </Modal.Title>
      </div>

      <ul className="list-group list-group-flush ml-2">
        {failedInvites.map((email) => (
          <li key={email} className={`list-group-item ${styles.email}`}>
            {email}
          </li>
        ))}
      </ul>

      <div className={`mt-4 mb-4 ${styles.btnContainer}`}>
        <KudoButton
          className={`cancelbutton mb-4 ${styles.btnWidth}`}
          onClick={() => {
            navigator.clipboard.writeText(failedInvites.toString());
            showNotification(t('notifications.CopiedEmails', { failedInvites: failedInvites.length }), 'success');
          }}
        >
          {t('failedInvitesCopyEmails')}
        </KudoButton>
        <Button
          variant="outline-secondary"
          className={`cancelbutton btn ${styles.btnWidth}`}
          onClick={redirectToOrg || onHide}
        >
          {t('failedInvitesClose')}
        </Button>
      </div>
    </Modal>
  );
};
