import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = (): JSX.Element => (
  <>
    <p>
      {` These Terms and Conditions of Service ("Agreement"), are entered into by and between KUDO Inc., a Delaware
      corporation, having principal offices at 225 W 35th Street, 16th floor, New York, NY 10001, United States
      ("Provider") and the Customer (the "Customer") setting up a KUDO account. Provider and Customer may be referred to
      herein collectively as the "Parties" or individually as a "Party." This Agreement will be effective as of date of
      the transaction (the "Effective Date"). WHEREAS, Customer desires to access Provider’s software-as-a-service AI
      offering (the “Services”), subject to the terms and conditions of this Agreement; NOW, THEREFORE, in consideration
      of the mutual covenants set forth herein, the Parties agree as follows:`}
    </p>
    <p>
      <strong>1. Free Trial</strong>
    </p>
    <p>
      {` The Services shall be provided to Customer until (i) fourteen (14) days from the Effective Date
         have been reached, not to exceed sixty minutes (60) minutes for each meeting. At any time during the 
         Free Trial Period or at the end of the Free Trial Period, Customer has the ability to sign up for a monthly or
          annual subscription to the Services pursuant to fees and conditions to be set forth by Provider. Provider 
          reserves the right to modify or cancel the right or access to free trial of Services at any time with no prior notice to Customer.`}
    </p>
    <p>
      <strong>2. Access and Use </strong>
    </p>
    <p>
      <span className={styles.subheading}>2.1. Provision of Access.</span>
      {` Subject to compliance with all the terms and conditions of this Agreement, Provider
      hereby grants Customer a non-exclusive right to access and use the Services. Provider shall provide to Customer
      the necessary credentials and network links or connections to allow Customer to access and use the Services.
      Customer may grant authorization to employees, contractors, and guests (“Authorized Users”) to access and use the
      Services under the rights granted to Customer pursuant to this Agreement.`}
    </p>
    <p>
      <span className={styles.subheading}>2.2. Updates to the Services.</span>
      {` During the Free Trial Period, Provider may update or modify the systems, platform
      environment, documentation, or other supporting materials used to provide the Services to reflect changes in
      technology, regulations, industry practices or patterns of system use, so long as Provider does not materially
      reduce the level of performance, functionality, or security of the Services and continues meeting its
      responsibilities hereunder. New functionality to the Services may be marketed separately by Provider and may
      require the payment of additional fees.`}
    </p>

    <p>
      <span className={styles.subheading}>2.3. Restrictive Country Regulations.</span>
      {` Provider is using multiple data centers to ensure high availability and
      optimal performance, regardless of users’ location. However, some governments have historically blocked foreign
      company’s data access, or temporarily disrupt or delay data flow, DNS, or proxies. Provider’s commitment to make
      the Services available to Customer may not or not always cover participants or interpreters using Provider’s
      Services inside or trying to connect to countries that restrict cross-border data flow.`}
    </p>

    <p>
      <span className={styles.subheading}>2.4. Suspension or Termination.</span>
      {` Notwithstanding anything to the contrary in this Agreement, Provider may temporarily suspend Customer's access to
      any portion or all of the Services, and Provider may terminate this Agreement, if Provider reasonably determines
      that: (i) Customer is using the Provider IP or Services for fraudulent or illegal activities in breach of this
      Agreement; (ii) Customer's use of the Services disrupts or poses a security risk to the Provider IP or to any
      other customer or vendor of Provider; (ii) subject to applicable law, Customer has ceased to continue its business
      in the ordinary course, made an assignment for the benefit of creditors, or become the subject of any bankruptcy,
      liquidation, dissolution, or similar proceeding; or (iii) Provider's provision of the Services to Customer or any
      Authorized User is prohibited by applicable law; (iv) any vendor of Provider has suspended or terminated
      Provider's access to or use of any third-party products required to enable Customer to access the Services; or (v)
      Provider is unable to provide Services for causes as described herein.`}
    </p>

    <p>
      <strong>3. Customer Responsibilities</strong>
    </p>
    <p>
      <span className={styles.subheading}>3.1. General.</span>
      {` Customer shall access and use the Services in accordance with this Agreement, applicable laws and government
      regulations (including applicable data protection and privacy laws). Customer is responsible and liable for all
      uses of the Services resulting from access provided by Customer, directly or indirectly, whether such access or
      use is permitted by or in violation of this Agreement. Without limiting the generality of the foregoing, Customer
      is responsible for all acts and omissions of Authorized Users, and any act or omission by an Authorized User that
      would constitute a breach of this Agreement.`}
    </p>
    <p>
      <span className={styles.subheading}>3.2. Technical Guidelines and Best Practices.</span>
      {` Customer is responsible to follow all guidelines delivered by Provider for optimum use of the Services.`}
    </p>

    <p>
      <strong>4. Speech Translation</strong>
    </p>
    <p>
      <span className={styles.subheading}>4.1. Disclaimer for Speech Translation.</span>
      {` The simultaneous speech translation of a meeting serves to facilitate communication and does not constitute an
      authentic or verbatim record of the proceedings. Provider does not review for accuracy any information that
      appears in a closed caption, machine-enabled interpretation or transcript. Only the original speech is authentic.`}
    </p>

    <p>
      <strong>5. Mutual Confidentiality Agreement </strong>
    </p>
    <p>
      {` During the Free Trial Period, either Party may disclose or make available to the other Party information about its
      business affairs, products, confidential intellectual property, trade secrets, personal data, the terms hereof,
      third-party confidential information, and other sensitive or proprietary information that should reasonably be
      understood to be confidential given the nature of the information and the circumstances of disclosure, whether
      orally, in written, electronic, or other form or media, whether or not marked or otherwise identified as
      "confidential" (collectively, "Confidential Information"). Confidential Information does not include information
      that, at the time of disclosure is: (a) in the public domain; (b) known to the receiving Party; (c) rightfully
      obtained by the receiving Party on a non-confidential basis from a third party; or (d) independently developed by
      the receiving Party.`}
    </p>
    <p>
      <span className={styles.subheading}>5.2. Non-Disclosure.</span>
      {` The receiving Party shall not disclose the disclosing Party's Confidential Information to any person or entity,
      except to the receiving Party's employees, Affiliates’ employees, or Authorized Contractors who have a need to
      know the Confidential Information to exercise their rights or perform their obligations hereunder and who have a
      duty or obligation of confidentiality no less stringent than that set forth herein. Notwithstanding the foregoing,
      each Party may disclose Confidential Information to the limited extent required (i) in order to comply with the
      order of a court or other governmental body, or if legally obliged to do so, provided that the Party making the
      disclosure shall first have given written notice to the other Party and made a reasonable effort to allow the
      other Party to obtain a protective order or other appropriate relief; or (ii) to establish a Party's rights under
      this Agreement.`}
    </p>
    <p>
      {`Each Party's obligations of non-disclosure with regard to Confidential Information shall survive the termination
      of the Free Trial Period for as long as the information disclosed during the Free Trial Period remains
      confidential, as defined herein.`}
    </p>

    <p>
      <strong>6. Security and Data Privacy </strong>
    </p>
    <p>
      <span className={styles.subheading}>6.1. Customer Data.</span>
      {` “Customer Data” shall mean all data, records, files, images, graphics, audio, video,
      photographs, documents, reports, forms, chat logs, participants' votes, meeting subject and participants,
      transcripts and other content and material, in any format, that are submitted, stored, posted, displayed, or
      otherwise transmitted by or on behalf of Customer or an Authorized User through Customer's use of the Services.
      Customer Data includes personal data which refers to any information relating to an identified or identifiable
      person such as a name, an identification number, voice, images, location data, an online identifier, this person's
      cultural or social identity, or other personal information (“Personal Data”).`}
    </p>
    <p>
      {`Customer represents and warrants that it has the right to provide the Customer Data to Provider and that such use
      does not violate or infringe on any rights of any third party. Under no circumstances will Provider be liable in
      any way for any (a) Customer Data that is transmitted or viewed while using the Services, (b) errors or omissions
      in Customer Data, or (c) any loss or damage of any kind incurred as a result of the use of, access to, or denial
      of access to Customer Data.`}
    </p>
    <p>
      <span className={styles.subheading}>6.2. Data Privacy.</span>
      {` Where Customer’s use of the Services includes the processing of Personal Data by Provider as a data processor
      subject to the General Data Protection Regulation (EU) 2016/679 (“GDPR”), Provider processes such Personal Data at
      the direction of Customer as data controller, and Provider endeavors to comply with the requirements of the
      aforementioned regulation. When Customer or any Authorized User provides to Provider Personal Data in connection
      with their use of the Services and Provider’s platform, Provider is acting in its capacity as a data processer as
      that term is defined under the GDPR; provided, however, that, as set forth elsewhere herein, Provider may in some
      situations (with separate consent from participants where required) act as a data controller in the analysis of
      Platform usage data for improvement of Provider’s products and services (“KUDO Controller Situations”) pursuant to
      Provider’s privacy policy. With the exception of KUDO Controller Situations, Customer acknowledges and agrees that
      it, and not Provider, is the data controller (as defined under the GDPR) of that Personal Data and Customer will
      be solely responsible to any data subject (as defined in the GDPR) requests received by either Party in connection
      with Customer’s and Authorized User’s use of the Services and Provider’s platform. With the exception of KUDO
      Controller Situations, any requests Provider receives directly from an Authorized User regarding exercise of
      rights under GDPR or any analogous data protection statute under applicable law will be re-directed to Customer
      for response. If Customer wishes Provider to take action in response to a request under GDPR concerning Personal
      Data, Customer shall contact Provider at Privacy@kudoway.com. Further details for Provider acting as processor for
      Customer are set out in the data processing addendum (if applicable) between the Parties which, together with the
      EU Standard Contractual Clauses (if applicable), is incorporated by reference into this Agreement. In case of
      inconsistency of the terms of this Agreement with the data processing agreement, the data processing agreement
      prevails. Customer’s entering into this Agreement shall be treated as signing the data processing addendum and the
      EU Standard Contractual Clauses and their appendices.`}
    </p>

    <p>
      <span className={styles.subheading}>6.3. Compliance with Law.</span>
      {` Provider will comply with all laws applicable to the provision of the Services, including applicable security
      breach notification laws. Customer is responsible for its legal and regulatory compliance in its use of any
      Services and shall make Provider aware of any Customer Data processed, stored or transmitted through the Services
      for which any specific legal requirements or regulations apply.`}
    </p>
    <p>
      <span className={styles.subheading}>6.4. Aggregated Statistics.</span>
      {`Provider may collect and compile audio and text data generated by Customer's use of the Services, including
      recordings (“Aggregated Statistics”). Such data is de-identified, maintained and used by Provider only in an
      aggregate manner to improve Provider’s products and services. As between Provider and Customer, all right, title,
      and interest in Aggregated Statistics, including all intellectual property rights therein, belong to and are
      retained solely by Provider. Customer agrees that Provider may use Aggregated Statistics to the extent and in the
      manner permitted under applicable law; provided that such Aggregated Statistics do not identify Customer, any
      participant, or Customer's Confidential Information; and further provided, for clarity, that any processing of
      Personal Data for deidentification and anonymization purposes by Provider shall result in a KUDO Controller
      Situation as to such processing.`}
    </p>

    <p>
      <strong>7. Intellectual Property </strong>
    </p>
    <p>
      <span className={styles.subheading}>7.1. Provider IP.</span>
      {` Customer acknowledges that Provider owns all right, title, and interest, including all intellectual property
      rights, in and to the Provider IP including the Services, documentation, and any and all intellectual property
      provided to Customer or any Authorized User in connection with the foregoing (“Provider IP”). Provider IP includes
      Aggregated Statistics. Customer will not delete or in any manner alter the copyright, trademark, and other
      proprietary notices of Provider.`}
    </p>
    <p>
      <span className={styles.subheading}>7.2. Customer Data IP.</span>
      {` Provider acknowledges that, as between Provider and Customer, Customer owns all right, title, and interest,
      including all intellectual property rights, in and to the Customer Data (other than Aggregated Statistics), that
      is submitted, posted, or otherwise transmitted by or on behalf of Customer or an Authorized User through
      Customer’s use of the Services, to the extent permitted by applicable law.`}
    </p>
    <p>
      {`Customer hereby grants to Provider a non-exclusive, royalty-free, worldwide license during the Term of this
      Agreement and for a period of one (1) year thereafter, to reproduce, distribute, and otherwise use, transmit and
      display the Customer Data and perform all acts with respect to the Customer Data limited to (a) what may be
      necessary for Provider to provide the Services to Customer and (b) for KUDO Controller Situations, where
      applicable, for analysis for purposes of improvement of Provider’s products and services. To the extent necessary,
      Customer also hereby grants to Provider a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license
      to compile and analyze any Customer Data incorporated within the Aggregated Statistics.`}
    </p>
    <p>8. Warranties, Provider Warranty Disclaimer and Limitation of Liability.</p>
    <p>
      <span className={styles.subheading}>8.1. Customer Warranties.</span>
      {` Customer warrants and represents that: (i) its signatory below has the power and authority to enter into this
      Agreement on behalf of Customer and to bind Customer to this Agreement; (ii) Customer is not a party to any
      restrictions, agreements or understandings whatsoever which would prevent or make unlawful Customer’s acceptance
      of the terms set forth in this Agreement or Customer’s performance hereunder; and (iii) Customer’s acceptance of
      the terms of this Agreement and the performance of Customer’s obligations hereunder do not and will not (with the
      passage of time) conflict with or constitute a breach or default of any contract, agreement or understanding, oral
      or written, to which Customer is a party or by which Customer is bound`}
    </p>
    <p>
      <span className={styles.subheading}>8.2. Provider Disclaimer of Warranties.</span>
      {` Except as expressly provided herein, the Provider IP and Services are provided "as is" and Provider hereby
      disclaims all warranties, whether express, implied, statutory, or otherwise. This is particularly relevant due to
      the relative novelty of Provider’s Services and trade practices for this developing industry. To the maximum
      extent permitted by applicable law, Provider specifically disclaims all implied warranties of merchantability,
      fitness for a particular purpose, title, non-infringement, and all warranties arising from course of dealing and
      usage of trade.`}
    </p>
    <p>
      {`Provider makes no warranty that the results of the use of the Services, will meet Customer's or any other person's
      requirements, achieve any intended result, or be compatible or work with any equipment, software or other
      services.`}
    </p>
    <p>Provider makes no warranty that the Services will operate without interruption.</p>
    <p>
      {`Provider makes no warranty that the Services will be secure from hacks or other forms of security breach, that the
      code or materials used in connection with the services is error free, or free of viruses, trojan horses, worms, or
      any other form of harmful code.`}
    </p>
    <p>
      {`Provider does not control what materials are uploaded by Customer or Authorized Users who use the Services.
      Accordingly, Provider cannot warrant that such uploaded materials are free of viruses, trojan horses, worms, or
      any other forms of harmful code. Additionally, Provider cannot warrant that any uploaded materials were done so
      lawfully in accordance with US and international copyright laws, or other laws relating to author’s rights.`}
    </p>
    <p>
      {`Customer should rely exclusively on its own due diligence and assume all responsibility for determining whether
      the Services, including the documentation generated thereby is accurate or sufficient for Customer’s purposes.`}
    </p>
    <p>
      <span className={styles.subheading}>8.3. Limitation of Liability.</span>
      {` To the maximum extent permitted under applicable law, neither Party shall have any liability to the other Party
      for any lost profits or revenues, replacement cost, or for any indirect, special, incidental, consequential, cover
      or punitive damages however caused, whether in contract, tort or under any other theory of liability, and whether
      or not the Party has been advised of the possibility of such damages.`}
    </p>
    <p>
      {`In no event will Provider's aggregate liability arising out of or related to this Agreement, under any legal or
      equitable theory, including breach of contract, tort (including negligence), strict liability, and otherwise
      exceed $100.`}
    </p>
    <p>
      <span className={styles.subheading}>8.4. Indemnification.</span>
      {` Customer agrees to indemnify, defend and hold harmless Provider, its affiliates, officers, directors, employees,
      consultants, agents, and suppliers from any and all third party claims, liability, damages and/or costs
      (including, but not limited to, attorneys’ fees) arising from Customer’s use of the Services, Customer’s violation
      of this Agreement or the infringement or violation by Customer or any other user of Customer’s account, of any
      intellectual property or other right of any person or entity or applicable law.`}
    </p>
    <p>
      <strong>9. General Provisions. </strong>
    </p>
    <p>
      <span className={styles.subheading}>9.1. Entire Agreement/Counterparts.</span>
      {` This Agreement, together with any other documents incorporated herein by reference, constitutes the sole and
      entire agreement of the Parties with respect to the subject matter of this Agreement, and supersedes all prior and
      contemporaneous understandings, agreements, and representations and warranties, both written and oral, with
      respect to such subject matter.`}
    </p>
    <p>
      <span className={styles.subheading}>9.2. Notices.</span>
      {`All notices, requests, claims, waivers, and other communications hereunder (each, a "Notice") must be in writing.
      Emails are an acceptable form of written communication.`}
    </p>
    <p>
      <span className={styles.subheading}>9.3. Force Majeure.</span>
      {` In no event shall either Party be liable to the other Party, or be deemed to have breached this Agreement, for any
      failure or delay in performing its obligations under this Agreement, if such failure or delay is caused by
      circumstances beyond such Party's reasonable control, including but not limited to: acts of God, pandemic,
      electrical power outage, riot or other civil unrest, labor stoppages, or government legislation. Both Parties
      shall use reasonable efforts to mitigate the effect of a force majeure event.`}
    </p>
    <p>
      <span className={styles.subheading}>9.4. Amendment and Modification. </span>
      {` No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized
      representative of each Party.`}
    </p>
    <p>
      <span className={styles.subheading}>9.5. Waiver.</span>
      {` No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that
      right.`}
    </p>
    <p>
      <span className={styles.subheading}>9.6. Severability.</span>
      {` If any provision of this Agreement is found to be invalid, illegal, or unenforceable in any jurisdiction, it shall
      not affect the enforceability of any other provision of this Agreement. Rather, the invalid, illegal, or
      unenforceable provision shall be modified and interpreted in good faith so as best to accomplish the objectives of
      the original provision to the fullest extent permitted by law.`}
    </p>
    <p>
      <span className={styles.subheading}>9.7. Assignment.</span>
      {` Neither Party may assign this Agreement or any right under this Agreement without the consent of the other Party,
      which shall not be unreasonably withheld. Notwithstanding the foregoing, either Party may assign this Agreement in
      its entirety without consent of the other Party, in connection with a merger, acquisition, corporate
      reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other
      Party. This Agreement is binding upon and inures to the benefit of the Parties and their respective permitted
      successors and assigns.`}
    </p>
    <p>
      <span className={styles.subheading}>9.8. Anti-Corruption.</span>
      {` The Parties shall comply with all applicable laws and regulations relating to anti-bribery and anti-corruption.
      Provider and Customer agree that they have not improperly given, offered, received, or agreed to accept any
      payment, gift, or other advantage which violates anti-corruption laws. If a Party learns of any violation of the
      above restriction, such Party will use reasonable efforts to promptly notify the other Party.`}
    </p>
    <p>
      <span className={styles.subheading}>9.9. Trade complier policy obligations.</span>
      {` Each Party undertakes to comply with applicable trade policies, export / import control and sanctions laws. Each
      Party represents that it is not named on any U.S. government denied-party list. Sanctions screening review implies
      the obligation of the Customer to screen and monitor its Authorized Users to identify if they are match to the
      restricted individuals or parties, proscribed countries, or prohibited purposes, as well as to screen and monitor
      parties with whom it engages (e.g., company names and country locations of suppliers, sub-contractors, employees,
      etc.). Customer is solely responsible for screening and monitoring his activity in the context of complying with
      such trade-related obligations. Customer shall indemnify and hold Provider harmless from all fines or damages
      imposed on Provider due to Customer’s failure to comply with this Section.`}
    </p>
    <p>
      <span className={styles.subheading}>9.10. Publicity.</span>
      {` Provider may use Customer name or logo to identify Customer as a customer. Use of Customer's name and logo will be
      revocable at any time by Customer.`}
    </p>
    <p>
      <span className={styles.subheading}>9.11. Governing Law.</span>
      {` This Agreement is governed by, and construed in accordance with, the internal laws of the State of New York,
      United States without giving effect to any conflict of law provision. The provisions of the United Nations
      Convention on the International Sale of Goods shall not apply to this Agreement.`}
    </p>
    <p>
      <span className={styles.subheading}>9.12. Choice of Forum. </span>
      {` The Parties shall use their best efforts to amicably settle any dispute, controversy, or claim arising out of
      their relationship under this Agreement, including through alternative dispute resolution procedures as may be
      agreed to by the Parties. If no amicable settlement is possible, any civil action or legal proceeding arising out
      of or in connection with this Agreement shall be brought to the exclusive jurisdiction and venue of the state and
      federal courts in New York County, NY, United States, each Party irrevocably submits to the exclusive jurisdiction
      of these courts, except for the exception provided below, and agrees that venue in New York County is both proper
      and convenient.`}
    </p>
    <p>
      {` If Customer is a non-US Government entity or international organization that has privileges or immunities from
      suits in U.S. courts, then at Customer’s choice, Provider consents that any dispute or difference arising out of
      or in connection with this Agreement shall be arbitrated by the ICC International Court of Arbitration in New York
      City, the number of arbitrators shall be one, and English shall be the language for arbitral proceedings.`}
    </p>
    <p>
      <span className={styles.subheading}>9.13. Signature. </span>
      {` By clicking to accept or agree to the Terms and Conditions of Service when this option is made available to
      Customer, Customer accepts and agrees to be bound and abide by this Agreement and such acceptance shall be deemed
      to be Customer's electronic signature to this Agreement.`}
    </p>
  </>
);

export default TermsAndConditions;
