/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Input, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import urls from 'urls';

import photoImg from 'assets/images/phone.svg';
import KudoButton from 'components/elements/facelift/Button';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { NEXT_STEP } from 'constants/index';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import { showAllNotifications } from 'helpers/showNotification';
import { addPhoneNumber, skipPhoneNumberAddition } from 'services/auth';
import styles from 'stylesheets/sharedStyles.module.scss';

const PhoneNumberAdder = ({ userId }) => {
  const [country, setCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const onCountryChange = (e) => {
    setCountry(e.target.value);
    setPhoneNumber('');
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      setIsSubmitting(true);
      const params = {
        clientId: new URLSearchParams(search).get('clientId'),
        userId,
        countryCode: `+${getCountryCallingCode(country)}`,
        phoneNumber: phoneNumber.slice(getCountryCallingCode(country).length + 1, phoneNumber.length),
        mfaEnabled: isMfaEnabled,
      };

      addPhoneNumber(params)
        .then(() => {
          history.push(urls.verifyPhoneNumber.concat(search));
          setIsSubmitting(false);
        })
        .catch((error) => {
          showAllNotifications(parseResponseErrors(error), 'error');
          setIsSubmitting(false);
        });
    } else {
      setIsPhoneValid(true);
    }
  };

  const handleSkip = (skipPermanently) => {
    const body = {
      userId,
    };
    const params = {
      clientId: new URLSearchParams(search).get('clientId'),
      redirectUri: new URLSearchParams(search).get('redirectUri'),
      skipPermanently,
    };

    skipPhoneNumberAddition(body, params)
      .then((res) => {
        if (res.data.body.flow.nextStep === NEXT_STEP.REDIRECT) {
          window.location.href = res.data.body.flow.details.redirectUri;
        } else if (res.data.body.flow.nextStep === NEXT_STEP.ADD_ORG) {
          history.push(urls.addOrganization.concat(search));
        } else if (res.data.body.flow.nextStep === NEXT_STEP.LOGIN) {
          history.push(urls.login.concat(search));
        }
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      });
  };

  return (
    <div className="user-form phone-number-form">
      <HeaderWithoutUser />
      <Container className={`body-container ${styles.container}`}>
        <Row>
          <Col md="12">
            <Form onSubmit={handleFormSubmit} className="user-form-inner">
              <div className="head">
                <h2>
                  <img src={photoImg} alt="phone" className="mr-2" />
                  {t('text.AddPhoneNumber')}
                </h2>
              </div>
              <div className="instructions">
                <p>{t('text.HeadingToAddNumber')}</p>
                <ul>
                  <li>{t('text.AvoidChanges')}</li>
                  <li>{t('text.TwoStepsVerification')}</li>
                </ul>
              </div>
              <Form.Group controlId="formBasicCountry">
                <Form.Label>{t('text.Country')}</Form.Label>
                <select className="form-control select" value={country} onChange={onCountryChange} required>
                  <option value="">{en['.ZZ']}</option>
                  {getCountries().map((countryName) => (
                    <option key={countryName} value={countryName}>
                      {en[countryName]}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group controlId="formBasicPhoneNumber">
                <Form.Label>{t('text.MobileNumber')}</Form.Label>
                <Input
                  name="mobileNumber"
                  className="form-control"
                  withCountryCallingCode
                  international
                  country={country}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e || '');
                    if (isPhoneValid) setIsPhoneValid(false);
                  }}
                  required
                />
                {(phoneNumber || isPhoneValid) && !isValidPhoneNumber(phoneNumber) && (
                  <p className="text-danger text-left phone-num-validate">{t('text.PhoneNumberUnrecognized')}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <Form.Check
                    inline
                    type="checkbox"
                    label={t('text.EnableMultiFactor')}
                    value={isMfaEnabled}
                    onChange={() => setIsMfaEnabled(!isMfaEnabled)}
                  />
                </div>
              </Form.Group>
              {isSubmitting ? (
                <KudoButton variant="primary" className="btn-block">
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </KudoButton>
              ) : (
                <KudoButton className="btn-block" type="submit">
                  {t('buttons.AddPhoneNumber')}
                </KudoButton>
              )}
              <div className="login-caption">
                <p>
                  <Button variant="link" onClick={() => handleSkip(false)}>
                    {t('buttons.Skip')}
                  </Button>
                  <Button variant="link" className="ml-4" onClick={() => handleSkip(true)}>
                    {t('buttons.DontShow')}
                  </Button>
                </p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.authReducer.userId,
});

PhoneNumberAdder.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(PhoneNumberAdder);
