import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';

import styles from './HelpTooltip.module.scss';

interface Props {
  text: string | JSX.Element;
  tooltipClass?: string;
}

const HelpTooltip = ({ text, tooltipClass = '' }: Props): JSX.Element => (
  <OverlayTrigger
    trigger={['hover', 'focus']}
    placement="top"
    overlay={
      <Tooltip id="tooltip" className={styles.popoverWithIcon}>
        {text}
      </Tooltip>
    }
  >
    <span className={styles.tooltipIconWrapper} role="tooltip">
      <i className={classnames('fas', 'fa-info-circle', styles.tooltipIconBefore)} />
      <i className={classnames('fal', 'fa-info-circle', styles.tooltipIconAfter)} />
    </span>
  </OverlayTrigger>
);

export default HelpTooltip;
