const URLs = {
  login: '/login',
  signup: '/signup',
  checkInbox: '/signup/check-inbox',
  magicLinkSignUpCheckInbox: '/magic-link-signup/check-inbox',
  magicLinkLoginCheckInbox: '/magic-link-login/check-inbox',
  forgotPassword: '/forgot-password',
  setPassword: '/set-password',
  addPhoneNumber: '/add-phone-number',
  verifyPhoneNumber: '/verify-phone-number',
  verifyMfa: '/verify-mfa',
  profile: '/profile',
  addOrganization: '/add-organization',
  organization: '/organization',
  inviteOrganizationUsers: '/organizations/:orgId/invite',
  generateInviteOrganizationUsers: (id) => `/organizations/${id}/invite`,
  acceptOrganizationInvitation: '/invite/:invitationId/acceptance',
  organizations: '/organizations/:id',
  generateOrganizationUrl: (id) => `/organizations/${id}`,
  manageUsers: '/manage-users',
  accountDeactivated: '/account-deactivated',
  linkVerification: '/link/verification',
  linkExpired: '/magic-link/expired',
  organizationDeactivated: '/organization-deactivated',
  invitationAccepted: '/invitation-accepted',
  deleteAccount: '/delete-consent',
  accountDeleted: '/account-deleted',
};

export default URLs;
