import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';

import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { setPassword } from 'services/auth';
import styles from 'stylesheets/sharedStyles.module.scss';

const SetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypedPassword, setShowRetypedPassword] = useState(false);
  const { state, search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  if (!state) history.push({ pathname: '/login', search });

  const Schema = Yup.object().shape({
    confirmationCode: Yup.string().required(t('validation.required')),
    password: Yup.string()
      .min(8, t('validation.min8Characters'))
      .required(t('validation.required'))
      .test('password matches email', t('password.containsEmail'), (val) => val !== state.email),
    retypedPassword: Yup.string()
      .required(t('validation.required'))
      .when('password', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], t('validation.passwordNotMatched')),
      }),
  });

  const initialValues = {
    email: state?.email,
    confirmationCode: '',
    password: '',
    retypedPassword: '',
  };

  return (
    <div className="user-form set-password-form">
      <HeaderWithoutUser />
      <Container className={`body-container ${styles.container}`}>
        <Row>
          <Col md="12">
            <Formik
              initialValues={initialValues}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting }) => {
                setPassword(values)
                  .then(() => {
                    setSubmitting(false);
                    showNotification(t('text.PasswordChangedSuccessfully'), 'success');
                    history.push({ pathname: '/login', search });
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    showAllNotifications(parseResponseErrors(error), 'error');
                  });
              }}
            >
              {({ isSubmitting }) => (
                <FormikForm className="user-form-inner">
                  <div className="d-flex flex-row">
                    <button className="back-link" type="button" onClick={history.goBack}>
                      <i className="fas fa-chevron-left mr-2" />
                      <span>{t('buttons.Back')}</span>
                    </button>
                  </div>
                  <div className="head">
                    <h2 className="font-weight-bold">{t('headings.ResetPassword')}</h2>
                    <p className="text">{t('text.WeVeSentVerificationCode')}</p>
                  </div>
                  <Form.Group controlId="formBasicConfirmationCode" className="m-b-20 text-left">
                    <Form.Label>{t('text.ResetCode')}</Form.Label>
                    <Field type="text" name="confirmationCode" className="form-control" autoComplete="off" />
                    <ErrorMessage name="confirmationCode" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="icon-show-holder text-left">
                    <Form.Label for="password">{t('text.NewPassword')}</Form.Label>
                    <InputGroup>
                      <Field
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        id="password"
                        className="form-control no-border-right no-box-shadow"
                      />
                      <Button className="show-link" onClick={() => setShowPassword(!showPassword)} variant="link">
                        {showPassword ? t('password.buttons.hide') : t('password.buttons.show')}
                      </Button>
                    </InputGroup>
                    <ErrorMessage name="password" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="icon-show-holder text-left">
                    <Form.Label>{t('setPassword.confirm')}</Form.Label>
                    <InputGroup>
                      <Field
                        type={showRetypedPassword ? 'text' : 'password'}
                        name="retypedPassword"
                        className="form-control no-border-right no-box-shadow"
                      />
                      <Button
                        className="show-link"
                        onClick={() => setShowRetypedPassword(!showRetypedPassword)}
                        variant="link"
                      >
                        {showRetypedPassword ? t('password.buttons.hide') : t('password.buttons.show')}
                      </Button>
                    </InputGroup>
                    <ErrorMessage name="retypedPassword" />
                  </Form.Group>

                  {isSubmitting ? (
                    <KudoButton variant="primary" className="reset-btn w-100">
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </KudoButton>
                  ) : (
                    <KudoButton className="reset-btn w-100" type="submit">
                      {t('setPassword.resetPassword')}
                    </KudoButton>
                  )}
                </FormikForm>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SetPassword;
