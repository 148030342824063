import { FocusEvent } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import { addOrganization } from 'redux/actions/authActions';
import * as Yup from 'yup';

import photoImg from 'assets/images/icon.svg';
import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { Footer } from 'components/shared/Footer';

const WEBSITE_PREFIX_REGEX = /^https?:\/\/.+/i;

const Schema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation.required')).min(2, i18n.t('validation.min2Characters')),
  website: Yup.string()
    .nullable(true)
    .test('websitelink', i18n.t('organization.invalidWebsite'), (website) => {
      if (!website) {
        return true;
      }

      return WEBSITE_PREFIX_REGEX.test(website);
    }),
});

const AddOrganization = (): JSX.Element => {
  const { t } = useTranslation();

  const initialValues = {
    name: '',
    website: '',
  };

  const dispatch = useDispatch();

  return (
    <div className="user-form reset-from">
      <Container className="body-container">
        <Row>
          <Col md="12">
            <a className="logoform" href="#home">
              {t('headings.Kudo')}
            </a>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(addOrganization(values, setSubmitting));
              }}
            >
              {({ isSubmitting, handleBlur, setFieldValue }) => (
                <FormikForm className="user-form-inner">
                  <div className="head mb-4">
                    <h2>
                      <img src={photoImg} alt="bag" className="mr-2 mb-1" />
                      <br />
                      {t('headings.CompleteYourOrganizationProfile')}
                    </h2>
                    <p>{t('text.EnableCollaborateWithColleagues')}</p>
                  </div>
                  <Form.Group controlId="formBasicOrganizationName" className="icon-show-holder text-left">
                    <Form.Label>{t('text.OrganizationName')}</Form.Label>
                    <Field type="text" name="name" className="form-control" />
                    <ErrorMessage name="name" />
                  </Form.Group>
                  <Form.Group controlId="formBasicOrganizationWebsite" className="icon-show-holder text-left">
                    <Form.Label>{t('text.OrganizationWebsiteURL')}</Form.Label>
                    <Field
                      type="text"
                      placeholder="https://"
                      onFocus={(e: FocusEvent<HTMLInputElement>) => {
                        if (!e.target.value) {
                          setFieldValue('website', 'https://');
                        }
                      }}
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        handleBlur(e);
                        if (e.target.value === 'https://') {
                          setFieldValue('website', '');
                        }
                      }}
                      name="website"
                      className="form-control"
                    />
                    <ErrorMessage name="website" />
                  </Form.Group>
                  {isSubmitting ? (
                    <KudoButton variant="primary">
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </KudoButton>
                  ) : (
                    <KudoButton type="submit">{t('buttons.GetStarted')}</KudoButton>
                  )}
                </FormikForm>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AddOrganization;
