import { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Field, Form as FormikForm, Formik } from 'formik';
import { getUserSessionInfo, login, loginWithMagicLink } from 'redux/actions/authActions';
import urls from 'urls';

import KudoButton from 'components/elements/facelift/Button';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import { NEXT_STEP } from 'constants/index';
import { usePostLoginNextStep } from 'hooks/usePostLoginNextStep';
import { RootState } from 'shared/types/store';
import styles from 'stylesheets/sharedStyles.module.scss';

import { EmailChangeHandler } from './EmailChangeHandler';

export const LoginForm = (): JSX.Element => {
  const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);
  const [shouldShowPasswordField, setShouldShowPasswordField] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userId, redirectUri, clientId } = useSelector(({ authReducer }: RootState) => authReducer);

  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const showPassword = query.get('showPassword');
  const queryClientId = query.get('clientId');
  const queryRedirectUri = query.get('redirectUri');
  const isAiUserLogin = queryRedirectUri?.includes('ai-free-trial/post-login');
  const EXTERNAL_AI_SIGNUP_LINK = 'https://kudoway.com/solutions/kudo-ai-speech-translator/';

  useEffect(() => {
    setShouldShowPasswordField(showPassword === 'true');
  }, [showPassword]);
  const dispatch = useDispatch();

  const onError = useCallback(
    (message) => {
      setError(`${message} ${t('text.PleaseTryAgain')}`);
    },
    [t]
  );

  const onSuccess = useCallback(
    (flow) => {
      if (flow.nextStep === NEXT_STEP.CHECK_INBOX) {
        history.push(urls.magicLinkLoginCheckInbox);
      } else if (flow.nextStep === NEXT_STEP.SHOW_PASSWORD) {
        setShouldShowPasswordField(true);
        setError('');
      }
    },
    [history]
  );
  const { handleNextStep } = usePostLoginNextStep();

  useEffect(() => {
    if (userId && !shouldShowPasswordField && queryClientId === clientId && queryRedirectUri === redirectUri) {
      setIsLoading(true);
      dispatch(getUserSessionInfo(queryClientId, userId, queryRedirectUri, handleNextStep, setIsLoading));
    }
  }, [
    queryClientId,
    userId,
    queryRedirectUri,
    handleNextStep,
    dispatch,
    shouldShowPasswordField,
    redirectUri,
    clientId,
  ]);

  return (
    <div className="user-form login-form-background-image">
      <HeaderWithoutUser />
      <HeapAnalytics />
      <Container className={`body-container ${styles.container}`}>
        <Row>
          {isLoading ? (
            <span className="mx-auto" data-testid="loader">
              <Skeleton width={440} height={270} count={1} />
            </span>
          ) : (
            <Col md="12">
              <Formik
                initialValues={{ email: query.get('email') ? query.get('email') : '' }}
                onSubmit={(values, { setSubmitting }) => {
                  values.email = values.email ? values.email.trim() : null;

                  if (shouldShowPasswordField) {
                    const params = {
                      redirectUri: query.get('redirectUri'),
                      clientId: query.get('clientId'),
                    };

                    dispatch(login(values, params, handleNextStep, setSubmitting, onError));
                  } else {
                    const params = {
                      ...values,
                      clientId: query.get('clientId'),
                      redirectUri: query.get('redirectUri'),
                    };

                    dispatch(loginWithMagicLink(params, setSubmitting, onSuccess, onError));
                  }
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <FormikForm className="user-form-inner">
                    <EmailChangeHandler
                      onChange={
                        shouldShowPasswordField
                          ? () => {
                              setShouldShowPasswordField(false);
                              setFieldValue('password', '');
                            }
                          : undefined
                      }
                    />
                    <div className="head">
                      <h2 className="font-weight-bold">{t('headings.LogIn')}</h2>
                    </div>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>{t('text.Email')}</Form.Label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        required
                        id="email"
                        data-testid="email"
                      />
                      {error && !shouldShowPasswordField ? (
                        <p className="text-danger login-details-validate">{error}</p>
                      ) : null}
                    </Form.Group>
                    {shouldShowPasswordField ? (
                      <Form.Group controlId="formBasicPassword" className="icon-show-holder">
                        <Form.Label htmlFor="password">{t('text.Password')}</Form.Label>
                        <InputGroup>
                          <Field
                            data-testid="password"
                            type={shouldShowPassword ? 'text' : 'password'}
                            name="password"
                            className="form-control no-border-right no-box-shadow"
                            required
                            id="password"
                          />
                          <Button
                            onClick={() => setShouldShowPassword(!shouldShowPassword)}
                            className="show-link"
                            variant="link"
                          >
                            {shouldShowPassword ? t('password.buttons.hide') : t('password.buttons.show')}
                          </Button>
                        </InputGroup>
                        {error ? <p className="text-danger login-details-validate">{error}</p> : null}
                      </Form.Group>
                    ) : null}
                    {shouldShowPasswordField ? (
                      <div className="login-caption mb-4">
                        <Link to={urls.forgotPassword.concat(search)}>{t('loginForm.forgotYourPassword')}</Link>
                      </div>
                    ) : null}
                    {isSubmitting ? (
                      <KudoButton variant="primary" className="w-100">
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      </KudoButton>
                    ) : (
                      <KudoButton className="w-100" type="submit" data-testid="submit-btn">
                        {shouldShowPasswordField ? t('buttons.Login') : t('buttons.Continue')}
                      </KudoButton>
                    )}
                    {!shouldShowPasswordField ? (
                      <div className="login-caption">
                        {t('magicLinkLogin.DoNotHaveAnAccount')}
                        <a href={isAiUserLogin ? EXTERNAL_AI_SIGNUP_LINK : urls.signup.concat(search)} className="ml-2">
                          {t('buttons.SignUp')}
                        </a>
                      </div>
                    ) : null}
                  </FormikForm>
                )}
              </Formik>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
