import { ChangeEvent } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import * as Yup from 'yup';

import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { forgetPassword } from 'services/auth';
import styles from 'stylesheets/sharedStyles.module.scss';

const Schema = Yup.object().shape({
  email: Yup.string().email(i18n.t('validation.invalidEmail')),
});

export const ForgotPassword = (): JSX.Element => {
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="user-form reset-from">
      <HeaderWithoutUser />
      <Container className={`body-container ${styles.container}`}>
        <Row>
          <Col md="12">
            <Formik
              enableReinitialize
              initialTouched={{
                email: true,
              }}
              initialValues={{ email: '' }}
              validationSchema={Schema}
              onSubmit={({ email }, { setSubmitting, setFieldError }) => {
                if (email === '') {
                  setFieldError('email', t('validation.required'));
                  setSubmitting(false);

                  return;
                }

                const params = { email, clientId: new URLSearchParams(search).get('clientId') };

                forgetPassword(params)
                  .then(() => {
                    setSubmitting(false);
                    showNotification(t('text.VerificationCodeSent'), 'success');
                    history.push({ pathname: '/set-password', state: { email }, search });
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    showAllNotifications(parseResponseErrors(error), 'error');
                  });
              }}
            >
              {({ isSubmitting, handleChange }) => (
                <FormikForm>
                  <div className="user-form-inner">
                    <div className="d-flex flex-row">
                      <button className="back-link" type="button" onClick={history.goBack}>
                        <i className="fas fa-chevron-left mr-2" />
                        <span>{t('buttons.Back')}</span>
                      </button>
                    </div>
                    <div className="head">
                      <h2 className="font-weight-bold">{t('headings.ForgotPassword')}</h2>
                      <p>{t('text.ForgotPasswordText')}</p>
                    </div>
                    <Form.Group controlId="formBasicEmail" className="icon-show-holder text-left">
                      <Form.Label>{t('text.Email')}</Form.Label>
                      <Field
                        name="email"
                        className="form-control"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                      />
                      <ErrorMessage name="email" />
                    </Form.Group>
                    <KudoButton className="reset-btn w-100" type="submit">
                      {isSubmitting ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      ) : (
                        t('buttons.GetResetCode')
                      )}
                    </KudoButton>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
