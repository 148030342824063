import React from 'react';

import SetPass from 'components/setPassword/SetPassword';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';

const SetPassword = () => (
  <div id="content">
    <HeapAnalytics />
    <SetPass />
  </div>
);

export default SetPassword;
