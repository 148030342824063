import { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import urls from 'urls';

import emailCheck from 'assets/images/email-icon.png';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { magicLinkLogin } from 'services/auth';
import sharedStyles from 'stylesheets/sharedStyles.module.scss';

import styles from './MagicLinkCheckInbox.module.scss';

const COUNTDOWN = 'countdown';

export const MagicLinkCheckInbox = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const sessionCountValue = JSON.parse(sessionStorage.getItem(COUNTDOWN) || 'null');
  const [countdown, setCountdown] = useState(sessionCountValue || 10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
      sessionStorage.setItem(COUNTDOWN, JSON.stringify(countdown));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);
  const sendNewLink = useCallback(() => {
    setCountdown(10);
    magicLinkLogin({
      clientId: location.state.clientId,
      email: location.state.email,
      redirectUri: location.state.redirectUri,
      passwordCheckRequired: false,
    })
      .then(() => {
        showNotification(t('checkInbox.resendNotification'), 'success');
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      });
  }, [location, t]);

  return (
    <div className="user-form checkinbox login-form-background-image">
      <HeaderWithoutUser />
      <HeapAnalytics />
      <Container className={`body-container ${sharedStyles.container}`}>
        <Row>
          <Col md="12">
            <div className={styles.container}>
              <p className={styles.title}>{t('checkinbox.checkYourinbox')}</p>
              <div className="mt-4 mb-4">
                <img src={emailCheck} alt="check-email" />
              </div>
              <p>
                {history.location.pathname === urls.magicLinkSignUpCheckInbox ? (
                  <div>
                    {t('checkInbox.confirmYourEmail')}
                    <p className={styles.resendButtonText}>
                      <Trans
                        t={t}
                        i18nKey="checkInbox.didNotGetEmail"
                        values={{
                          seconds: countdown,
                        }}
                        components={[
                          <button
                            type="button"
                            className={`${styles.resendButton} ${countdown ? styles.disabled : ''}`}
                            onClick={sendNewLink}
                            aria-label="resend"
                          />,
                        ]}
                      />
                    </p>
                    <span className={styles.contactUsText}>
                      {t('checkInbox.youNeedAnyHelp')}{' '}
                      <span className={styles.supportEmail}>{t('checkInbox.supportEmail')}</span>
                    </span>
                  </div>
                ) : null}
                {history.location.pathname === urls.magicLinkLoginCheckInbox ? t('checkInbox.sentLoginLink') : null}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
