/* eslint-disable @typescript-eslint/naming-convention */
import { Helmet } from 'react-helmet';

import { Organization } from 'shared/types/organization';

enum OrganizationType {
  TEST = 'Test',
  CUSTOMER = 'Customer',
}

interface Props {
  userId?: string;
  organization?: Organization;
}

export const HeapAnalytics = ({ userId, organization }: Props): JSX.Element => {
  const appId = process.env.REACT_APP_HEAP;

  const orgProps = organization?.id
    ? {
        'Organization ID': organization.id,
        'Organization Name': organization.name,
        'Organization Website': organization.website,
        'Organization Customer Type': organization.test ? OrganizationType.TEST : OrganizationType.CUSTOMER,
      }
    : {};

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: `
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load(${appId});
          ${userId ? `heap.identify('${userId}');` : ''}
          heap.addUserProperties(${JSON.stringify(orgProps)})
          `,
        },
      ]}
    />
  );
};
