import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { DropdownMenu, DropdownMenuItem } from 'components/elements/dropdown/DropdownMenu';

import styles from './MembersDropdown.module.scss';

interface UpdateMemberRoleObject {
  userId: string;
  userName: string;
  shouldMakeAdmin: boolean;
}

interface MembersDropdownProps {
  shouldShowRoleChangeOption: boolean;
  isAdmin: boolean;
  userId: string;
  userName: string;
  invitationId: string;
  email: string;
  setUpdateMemberRoleModalDetails: (obj: UpdateMemberRoleObject) => void;
  handleRemoveOrgMember: (
    setIsLoading: (a: boolean) => void,
    userId: string,
    userName: string,
    invitationId: string
  ) => void;
  handleRemoveOrgMemberWithoutUserId: (setIsLoading: (a: boolean) => void, email: string, invitationId: string) => void;
}

const MembersDropdown = ({
  isAdmin,
  shouldShowRoleChangeOption,
  userId,
  invitationId,
  userName,
  email,
  setUpdateMemberRoleModalDetails,
  handleRemoveOrgMember,
  handleRemoveOrgMemberWithoutUserId,
}: MembersDropdownProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" data-testid="spinner" />
      ) : (
        <DropdownMenu data-testid={`actions-dropdown-${userId}`}>
          <DropdownMenuItem
            className={styles.menuItemTexts}
            onClick={() =>
              userId
                ? handleRemoveOrgMember(setIsLoading, userId, userName, invitationId)
                : handleRemoveOrgMemberWithoutUserId(setIsLoading, email, invitationId)
            }
            data-testid={`user-${userId}`}
          >
            {t('organizations.dropdown.remove')}
          </DropdownMenuItem>
          {shouldShowRoleChangeOption ? (
            <DropdownMenuItem
              onClick={() => {
                setUpdateMemberRoleModalDetails({ userId, userName, shouldMakeAdmin: !isAdmin });
              }}
            >
              {isAdmin ? t('organization.member.makeMember') : t('organization.member.makeAdmin')}
            </DropdownMenuItem>
          ) : null}
        </DropdownMenu>
      )}
    </>
  );
};

export default MembersDropdown;
