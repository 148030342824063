import { ButtonProps as BootstrapButtonProps } from 'react-bootstrap';
import BootstrapButton from 'react-bootstrap/Button';
import classnames from 'classnames';

import styles from './Button.module.scss';

const Button = ({ children, className, ...props }: BootstrapButtonProps): JSX.Element => (
  <BootstrapButton {...props} bsPrefix="kss-btn" className={classnames(className, styles.kssBtn)}>
    {children}
  </BootstrapButton>
);

export default Button;
