import { matchPath, useLocation } from 'react-router-dom';
import URLs from 'urls';

const useCurrentOrganizationId = (): string | undefined => {
  const { pathname } = useLocation();

  return matchPath<{ id?: string }>(pathname, {
    path: URLs.organizations,
  })?.params.id;
};

export { useCurrentOrganizationId };
