import { useTranslation } from 'react-i18next';

import styles from './FooterWithoutUser.module.scss';

const currentYear = new Date().getUTCFullYear();

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <p className={styles.footerText}>
        {t('footer.rightsReserved', {
          currentYear,
          version: '1.6.1',
        })}
      </p>
      <ul className={styles.footerLink}>
        <li className={styles.footerListItem}>
          <a href="https://kudoway.com/terms-of-use/" target="_blank" rel="noreferrer" className={styles.footerLink}>
            {t('footer.termsOfUse')}
          </a>
        </li>
        <li className={styles.footerListItem}>
          <span className={styles.footerDot}>
            <i className="fas fa-circle" />
          </span>
        </li>
        <li className={styles.footerListItem}>
          <a href="https://kudoway.com/cookies-policy/" target="_blank" rel="noreferrer" className={styles.footerLink}>
            {t('footer.cookiePolicy')}
          </a>
        </li>
        <li className={styles.footerListItem}>
          <span className={styles.footerDot}>
            <i className="fas fa-circle" />
          </span>
        </li>
        <li className={styles.footerListItem}>
          <a href="https://kudoway.com/privacy-policy/" target="_blank" rel="noreferrer" className={styles.footerLink}>
            {t('footer.privacyPolicy')}
          </a>
        </li>
        <li className={styles.footerListItem}>
          <span className={styles.footerDot}>
            <i className="fas fa-circle" />
          </span>
        </li>
        <li className={styles.footerListItem}>
          <a href="https://support.kudoway.com/hc/en-us" target="_blank" rel="noreferrer" className={styles.footerLink}>
            {t('footer.support')}
          </a>
        </li>
      </ul>
    </div>
  );
};
