import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button as BSButton, Modal, Spinner } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { _ } from 'gridjs-react';
import {
  getOrgMembers,
  removeOrgMember,
  removeOrgMemberWithoutUserId,
  setOrganizationMemberRoles,
} from 'redux/actions/authActions';
import urls from 'urls';

import { Button } from 'components/elements/Button';
import KudoButton from 'components/elements/facelift/Button';
import { Grid } from 'components/shared/Grid';
import showNotification from 'helpers/showNotification';
import { useCurrentOrganizationId } from 'hooks/useCurrentOrganizationId';
import { RootState } from 'shared/types/store';
import { getFeatureFlags } from 'shared/utils/featureFlags';

import MembersDropdown from './MembersDropdown';
import { UpdateOrganizationModal } from './UpdateOrganizationModal';

import styles from './Organization.module.scss';

const enum Role {
  ADMIN = 'Admin',
  MEMBER = 'Member',
}

export const Organization = (): JSX.Element | null => {
  const shouldShowMembers = getFeatureFlags().WithMultiUserOrganizations;
  const { t } = useTranslation();
  const [areMembersLoading, setAreMembersLoading] = useState(false);
  const [shouldShowOrganizationModal, setShouldShowOrganizationModal] = useState(false);
  const [updateMemberRoleModalDetails, setUpdateMemberRoleModalDetails] =
    useState<{ userId: string; userName: string; shouldMakeAdmin: boolean }>();
  const [isUpdateMemberRolesSubmitting, setIsUpdateMemberRolesSubmitting] = useState(false);
  const { members, organizations } = useSelector(({ authReducer }: RootState) => authReducer);

  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const history = useHistory();
  const currentOrganization = useMemo(
    () => organizations.find((organization) => organization.id === orgId),
    [orgId, organizations]
  );

  const handleRemoveOrgMember = useCallback(
    (setIsLoading: (a: boolean) => void, userId: string, userName: string, invitationId: string): void => {
      setIsLoading(true);
      dispatch(removeOrgMember(orgId, currentOrganization?.name, userId, userName, setIsLoading, invitationId));
    },
    [dispatch, orgId, currentOrganization]
  );

  const handleRemoveOrgMemberWithoutUserId = useCallback(
    (setIsLoading: (a: boolean) => void, email: string, invitationId: string): void => {
      setIsLoading(true);
      dispatch(removeOrgMemberWithoutUserId(orgId, currentOrganization?.name, email, setIsLoading, invitationId));
    },
    [dispatch, orgId, currentOrganization]
  );

  const closeMemberRoleModal = (): void => {
    if (!isUpdateMemberRolesSubmitting) {
      setUpdateMemberRoleModalDetails(undefined);
    }
  };

  const handleConfirmRoleChangeClick = (userId: string, userName: string, shouldMakeAdmin: boolean): void => {
    if (orgId) {
      setIsUpdateMemberRolesSubmitting(true);

      setOrganizationMemberRoles(orgId, [
        { userId, roles: shouldMakeAdmin ? ['org_admin', 'org_member'] : ['org_member'] },
      ])
        .then(() => {
          showNotification(
            t(`organization.member.${shouldMakeAdmin ? 'makeAdmin' : 'makeMember'}.success`, { userName }),
            'success'
          );
          setAreMembersLoading(true);
          dispatch(getOrgMembers(orgId, setAreMembersLoading));
        })
        .finally(() => {
          setIsUpdateMemberRolesSubmitting(false);
          setUpdateMemberRoleModalDetails(undefined);
        });
    }
  };

  const handleOrganizationClick = useCallback((): void => {
    if (!currentOrganization) {
      return;
    }

    if (!currentOrganization.name) {
      showNotification(t('notifications.NoOrganizationName'), 'warning');
      setShouldShowOrganizationModal(true);
    } else {
      history.push(urls.generateInviteOrganizationUsers(orgId));
    }
  }, [currentOrganization, history, orgId, t]);

  useEffect(() => {
    if (shouldShowMembers) {
      setAreMembersLoading(true);
      dispatch(getOrgMembers(orgId, setAreMembersLoading));
    }
  }, [dispatch, orgId, shouldShowMembers]);

  const columns = useMemo(
    () => [
      {
        name: t('organizations.users.name').toUpperCase(),
        id: 'name',
        formatter: (name: string) =>
          _(<span className={name.split('-')[0] === 'Pending ' ? styles.invited : undefined}>{name}</span>),
      },
      { name: t('organizations.users.email').toUpperCase(), id: 'email' },
      { name: t('organizations.users.userRole').toUpperCase(), id: 'role' },
      {
        id: 'userId',
        sort: 'false',
        formatter: (
          userId: string,
          {
            cells,
          }: {
            cells: {
              [0]: { data: string };
              [1]: { data: string };
              [2]: { data: string[] };
              [4]: { data: string };
              [5]: { data: string };
            };
          }
        ) => {
          const {
            0: { data: userName },
            1: { data: email },
            2: { data: roles },
            4: { data: status },
            5: { data: invitationId },
          } = cells;

          return _(
            currentOrganization?.orgRoles?.includes('org_admin') ? (
              <MembersDropdown
                isAdmin={roles.includes(Role.ADMIN)}
                handleRemoveOrgMember={handleRemoveOrgMember}
                handleRemoveOrgMemberWithoutUserId={handleRemoveOrgMemberWithoutUserId}
                userId={userId}
                email={email}
                invitationId={invitationId}
                userName={userName}
                shouldShowRoleChangeOption={status !== 'INVITED'}
                setUpdateMemberRoleModalDetails={setUpdateMemberRoleModalDetails}
              />
            ) : null
          );
        },
      },
      { name: 'status', id: 'status', hidden: true },
      { name: 'invitationId', id: 'invitationId', hidden: true },
    ],
    [t, handleRemoveOrgMember, handleRemoveOrgMemberWithoutUserId, currentOrganization]
  );

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="account-profile">
      <Container className="body-container">
        <Row>
          <Col md="12" className="pl-sm-0 pr-sm-0">
            <div className={styles.organizationName} data-testid="heading">
              {currentOrganization.name
                ? currentOrganization.name
                : t('organizations.placeholder', {
                    index: organizations.findIndex(({ id }) => id === currentOrganization.id) + 1,
                  })}
            </div>
            <div className="user-form-inner mt-5 mb-0">
              <Row>
                <div className="single-line">
                  <span className="heading">{t('text.Name')}</span>
                  <span className="value">{currentOrganization.name}</span>
                  <span className="edit">
                    {currentOrganization?.orgRoles.includes('org_admin') ? (
                      <Button
                        shouldShowIcon
                        iconClass="far fa-pencil"
                        variant="link"
                        onClick={() => setShouldShowOrganizationModal(true)}
                      />
                    ) : null}
                  </span>
                </div>
              </Row>
              <hr />
              <Row>
                <div className="single-line">
                  <span className="heading">{t('text.Domain')}</span>
                  <span className="value">{currentOrganization.website}</span>
                  <span className="edit">
                    {currentOrganization?.orgRoles.includes('org_admin') ? (
                      <Button
                        shouldShowIcon
                        iconClass="far fa-pencil"
                        variant="link"
                        onClick={() => setShouldShowOrganizationModal(true)}
                      />
                    ) : null}
                  </span>
                </div>
              </Row>
              <UpdateOrganizationModal
                shouldShow={shouldShowOrganizationModal}
                onHide={() => setShouldShowOrganizationModal(false)}
                organization={currentOrganization}
              />
            </div>
          </Col>
          {shouldShowMembers ? (
            <Col md="8" className="pl-sm-0 pr-sm-0 ml-auto mr-auto">
              <Row className={styles.titleRow}>
                <p className={styles.usersTitle}>{t('organizations.users.title')}</p>

                {currentOrganization.orgRoles.includes('org_admin') ? (
                  <KudoButton
                    type="button"
                    className={styles.inviteBtn}
                    onClick={handleOrganizationClick}
                    data-testid="invite-btn"
                  >
                    {t('organizations.users.inviteUsers')}
                  </KudoButton>
                ) : null}
              </Row>
              {areMembersLoading ? <Skeleton height={50} count={5} /> : <Grid columns={columns} data={members} />}
            </Col>
          ) : null}
        </Row>
      </Container>
      <Modal show={!!updateMemberRoleModalDetails} onHide={closeMemberRoleModal}>
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            {t(
              updateMemberRoleModalDetails?.shouldMakeAdmin
                ? 'organization.member.makeAdmin.modal.title'
                : 'organization.member.makeMember.modal.title'
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.permissionsTextColor}>
          {updateMemberRoleModalDetails?.shouldMakeAdmin ? (
            <>
              {t('organization.member.makeAdmin.modal.start')}
              <ul>
                <li>{t('organization.member.makeAdmin.permissions.book')}</li>
                <li>{t('organization.member.makeAdmin.permissions.inviteAndRemove')}</li>
                <li>{t('organization.member.makeAdmin.permissions.editProfile')}</li>
              </ul>
            </>
          ) : (
            <>
              {t('organization.member.makeMember.modal.start')}
              <ul>
                <li>{t('organization.member.makeMember.permissions.book')}</li>
              </ul>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={closeMemberRoleModal} className={styles.cancelBtn}>
            {t('organization.member.modal.all.button.cancel')}
          </button>
          <BSButton
            variant="primary"
            disabled={isUpdateMemberRolesSubmitting}
            onClick={() => {
              if (updateMemberRoleModalDetails) {
                handleConfirmRoleChangeClick(
                  updateMemberRoleModalDetails.userId,
                  updateMemberRoleModalDetails.userName,
                  updateMemberRoleModalDetails.shouldMakeAdmin
                );
              }
            }}
          >
            {isUpdateMemberRolesSubmitting ? (
              <Spinner size="sm" animation="border" />
            ) : (
              <>
                {updateMemberRoleModalDetails?.shouldMakeAdmin
                  ? t('organization.member.makeAdmin.modal.button.submit')
                  : t('organization.member.makeMember.modal.button.submit')}
              </>
            )}
          </BSButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
