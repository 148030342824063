import { useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import urls from 'urls';

import { ReactComponent as SadFace } from 'assets/images/sad-face.svg';
import { getLoginUrl } from 'components/acceptOrganizationInvitation/AcceptOrganizationInvitation.utils';
import KudoButton from 'components/elements/facelift/Button';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import { showAllNotifications } from 'helpers/showNotification';
import { magicLinkLogin } from 'services/auth';

import styles from './LinkExpired.module.scss';

const LinkExpired = (): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();

  const { t } = useTranslation();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const defaultEmail = queryParams.get('email');

  const sendNewLink = useCallback(() => {
    const query = new URLSearchParams(search);

    setIsSubmitting(true);
    magicLinkLogin({
      clientId: query.get('clientId'),
      email: query.get('email'),
      redirectUri: query.get('redirectUri'),
      passwordCheckRequired: false,
    })
      .then(() => {
        history.push(urls.magicLinkLoginCheckInbox);
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      })
      .finally(() => setIsSubmitting(false));
  }, [history, search]);

  return (
    <div className={styles.LinkExpired}>
      <HeapAnalytics />
      <HeaderWithoutUser />
      <Container className={styles.Container}>
        <Row>
          <Col md={12}>
            <div className={styles.Card}>
              <SadFace aria-label={t('alt.SadFace')} className={styles.SadFace} />
              <p className={styles.Heading}>{t('linkExpired.LinkHasExpired')}</p>
              {queryParams.get('isMagicLinkEnabled') === 'true' ? (
                <>
                  <KudoButton className="mb-2" onClick={sendNewLink}>
                    {isSubmitting ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      t('linkExpired.getNewLink')
                    )}
                  </KudoButton>
                  <p className={styles.Text}>{t('linkExpired.NewLinkCanBeUsedOnce')}</p>
                </>
              ) : (
                <KudoButton
                  className="mb-2"
                  onClick={() => {
                    if (defaultEmail) {
                      history.push(getLoginUrl(defaultEmail));
                    }
                  }}
                >
                  {t('buttons.Login')}
                </KudoButton>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export { LinkExpired };
