import i18n from 'i18n';

export const parseResponseErrors = (responseError) => {
  const { response: { data: { errors, error } = {}, status } = {} } = responseError || {};

  if (status === 401 && error === 'Unauthorized') {
    return [i18n.t('notifications.sessionExpired')];
  }

  if (errors?.length > 0) {
    return errors.map((error) => error.message);
  }

  return ['Something went wrong!'];
};
