import React from 'react';

import PhoneNumber from 'components/phoneNumber/PhoneNumberAdder';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';

const PhoneNumberAdder = () => (
  <>
    <HeapAnalytics />
    <PhoneNumber />
  </>
);

export default PhoneNumberAdder;
