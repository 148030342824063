import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProfilePicture from './ProfilePicture';

import styles from './Header.module.scss';

const Header = ({ name, profilePic }) => (
  <header id="header">
    <Container fluid className={styles.headerContainer}>
      <Navbar expand="lg">
        <Navbar.Brand href="#">Kudo</Navbar.Brand>
        <Nav>
          <NavLink className="account" to="/" onClick={() => null}>
            Account
          </NavLink>
        </Nav>
        <ProfilePicture name={name} profilePic={profilePic} />
      </Navbar>
    </Container>
  </header>
);

Header.propTypes = {
  name: PropTypes.string,
  profilePic: PropTypes.string,
};

Header.defaultProps = {
  name: null,
  profilePic: null,
};

export default Header;
