import { combineReducers } from 'redux';

import authReducer from './authReducer';

const appReducer = combineReducers({
  // * Import and list all your reducers here
  authReducer,
});

export const rootReducer = (state, action) => appReducer(state, action);
