import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import { signupWithMagicLink } from 'redux/actions/authActions';
import urls from 'urls';
import * as Yup from 'yup';

import Dropdown from 'components/elements/Dropdown';
import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import { Popup } from 'components/shared/Popup';
import { ROLES } from 'constants/index';
import { generateMagicLinkLoginUrl, reArrangeAvailableRegions } from 'helpers/authHelpers';
import showNotification from 'helpers/showNotification';
import { useHeapApi } from 'hooks/useHeapApi';
import { getAvailableRegions } from 'services/auth';
import { Region } from 'shared/types/region';
import sharedStyles from 'stylesheets/sharedStyles.module.scss';

import styles from './SignupForm.module.scss';

export const SignupForm = (): JSX.Element => {
  const [availableRegions, setAvailableRegions] = useState<Region[]>([]);
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryString = new URLSearchParams(search);
  const defaultEmail = queryString.get('email');
  const invitationId = queryString.get('invitationId');
  const redirectUri = queryString.get('redirectUri');
  const [shouldShowAiPopup, setShouldShowAiPopup] = useState(redirectUri?.includes('ai-free-trial/post-login'));
  const shouldShowFullNameField = queryString.get('defaultFullName') === 'false' || invitationId;
  const shouldShowUserPiiField = queryString.get('defaultUserPii') === 'false';
  const heapApi = useHeapApi();
  const clientId = queryString.get('clientId');
  const shouldShowOrganizationField =
    queryString.get('defaultOrganizationName') === 'false' &&
    (queryString.get('role') || ROLES.CLIENT) === ROLES.CLIENT;

  const ONLY_WHITESPACE_CHARACTERS_REGEX = /^\s+$/;

  const closeAiPopup = (): void => {
    setShouldShowAiPopup(false);
  };

  const Schema = Yup.object().shape({
    fullName: Yup.string()
      .nullable()
      .test('fullName', (fullName, { createError, path }) => {
        if (!shouldShowFullNameField) {
          return true;
        }
        if (!fullName) {
          return createError({ path, message: i18n.t('validation.required') });
        }
        if (ONLY_WHITESPACE_CHARACTERS_REGEX.test(fullName)) {
          return createError({ path, message: t('validation.invalidValue') });
        }
        if (fullName.length < 2) {
          return createError({ path, message: t('validation.tooShort') });
        }
        if (fullName.length > 255) {
          return createError({ path, message: t('validation.tooLong') });
        }

        return true;
      }),
    email: Yup.string().required(i18n.t('validation.required')).email(t('validation.invalidEmail')),
    organizationName: Yup.string()
      .nullable()
      .test('organizationName', (organizationName, { createError, path }) => {
        if (!shouldShowOrganizationField) {
          return true;
        }

        if (!organizationName) {
          return createError({ path, message: i18n.t('validation.required') });
        }
        if (ONLY_WHITESPACE_CHARACTERS_REGEX.test(organizationName)) {
          return createError({ path, message: t('validation.invalidValue') });
        }
        if (organizationName.length < 2) {
          return createError({ path, message: t('validation.tooShort') });
        }

        return true;
      }),
  });

  const initialValues = {
    fullName: null,
    email: defaultEmail ?? '',
    role: queryString.get('role') || ROLES.CLIENT,
    clientId: queryString.get('clientId'),
    region: shouldShowUserPiiField ? availableRegions[0]?.value : null,
    organizationName: null,
    invitationId,
    redirectUri,
  };

  useEffect(() => {
    getAvailableRegions().then((res) => {
      if (res.data.body) {
        const apiRegions = reArrangeAvailableRegions(res.data.body);

        setAvailableRegions(apiRegions);
      }
    });
  }, []);

  const onSuccess = useCallback(
    (email: string) => {
      if (redirectUri?.includes('ai-free-trial/post-login')) {
        heapApi?.identify(email);
        heapApi?.track('AI Signup', {
          email,
        });
      }
      history.push({
        pathname: urls.magicLinkSignUpCheckInbox,
        state: { email, clientId, redirectUri },
      });
    },
    [clientId, heapApi, history, redirectUri]
  );

  return (
    <div className="user-form newbg">
      <HeaderWithoutUser />
      <HeapAnalytics />
      {shouldShowAiPopup ? <Popup onClose={closeAiPopup} /> : null}
      <Container
        className={classnames('body-container', sharedStyles.container, { [styles.disable]: shouldShowAiPopup })}
      >
        <Row className={styles.marginTop}>
          <Col md="6">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting }) => {
                if (values.clientId === null) {
                  showNotification(t('signUp.NoClientId'), 'error');
                  setSubmitting(false);
                } else {
                  dispatch(signupWithMagicLink(values, onSuccess, setSubmitting));
                }
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <FormikForm className="user-form-inner">
                  <div className="head">
                    <div className={styles.heading}>{t('text.signup.setupAccount')}</div>
                    <h2 className="font-weight-bold">{t('headings.SignUp')}</h2>
                    {defaultEmail ? (
                      <span className={styles.kudoText}>
                        {t('inviteSignUp.preEmailText')}
                        <span className={styles.defaultEmail}>{t('inviteSignUp.defaultEmail', { defaultEmail })}</span>
                        {t('inviteSignUp.postEmailText')}
                      </span>
                    ) : (
                      <span className={styles.kudoText}>{t('signup.magicLink.createKudoAccount')}</span>
                    )}
                  </div>
                  {shouldShowFullNameField ? (
                    <Form.Group controlId="formBasicFullName" className="text-left">
                      <Form.Label>{t('text.FullName')}</Form.Label>
                      <Field type="text" name="fullName" className="form-control" aria-label="fullName" />
                      <ErrorMessage name="fullName" />
                    </Form.Group>
                  ) : null}
                  {!defaultEmail ? (
                    <Form.Group controlId="formBasicEmail" className="text-left">
                      <Form.Label>{t('text.Email')}</Form.Label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        aria-label="email"
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('email', event.target.value.trim())
                        }
                      />
                      <ErrorMessage name="email" />
                    </Form.Group>
                  ) : null}

                  {shouldShowUserPiiField ? (
                    <Form.Group
                      controlId="formBasicPassword"
                      data-testid="regions-dropdown"
                      className="icon-show-holder"
                    >
                      <Form.Label>{t('text.StorePersonalData')}</Form.Label>
                      <Dropdown className="form-control text-dark h-100" name="region" options={availableRegions} />
                    </Form.Group>
                  ) : null}

                  {shouldShowOrganizationField ? (
                    <Form.Group controlId="formBasicOrganizationName" className="text-left">
                      <Form.Label>{t('text.OrganizationName')}</Form.Label>
                      <Field
                        type="text"
                        name="organizationName"
                        className="form-control"
                        aria-label="organizationName"
                      />
                      <ErrorMessage name="organizationName" />
                    </Form.Group>
                  ) : null}

                  <div className="user-from-caption">
                    <p>
                      <span>{t('signup.agree.PolicyText')}</span>
                      <a href="https://kudoway.com/terms-of-use/" target="_blank" rel="noreferrer">
                        {t('magicLink.signUp.TermsOfService')}
                      </a>
                      {` ${t('general.text.and')} `}
                      <a href="https://kudoway.com/privacy-policy/" target="_blank" rel="noreferrer">
                        {t('signup.agree.PrivacyPolicy')}
                      </a>
                    </p>
                  </div>
                  {isSubmitting ? (
                    <KudoButton variant="primary" className="w-100">
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </KudoButton>
                  ) : (
                    <KudoButton className="w-100" type="submit">
                      {t('buttons.SignUp')}
                    </KudoButton>
                  )}
                  <div className="login-caption">
                    {!defaultEmail && (
                      <p>
                        {t('text.HaveAnAccount')}
                        <Link to={generateMagicLinkLoginUrl(queryString)} className="ml-1">
                          {t('buttons.LogIn')}
                        </Link>
                      </p>
                    )}
                  </div>
                </FormikForm>
              )}
            </Formik>
          </Col>
          <Col md="6" className={styles.textContainer}>
            <div className={styles.textbox}>
              <h4 className={styles.textboxHeading}>{t('text.signup.toStartBooking')}</h4>
              <p className={styles.subheading}>{t('text.signup.fromYourAccount')}</p>
              <div className={styles.item}>
                <i className={classnames('fas', 'fa-check-circle', styles.checkIcon)} />
                <span>{t('text.signup.hostmeetings')}</span>
              </div>
              <div className={styles.item}>
                <i className={classnames('fas', 'fa-check-circle', styles.checkIcon)} />
                <span>{t('text.signup.viewAndManage')}</span>
              </div>
              <div className={styles.item}>
                <i className={classnames('fas', 'fa-check-circle', styles.checkIcon)} />
                <span>{t('text.signup.seeDetails')}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
