import React from 'react';

import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import VerifyPhoneNumber from 'components/verifyPhoneNumber/verifyPhoneNumber';

const PhoneNumberVerify = () => (
  <>
    <HeapAnalytics />
    <VerifyPhoneNumber />
  </>
);

export default PhoneNumberVerify;
