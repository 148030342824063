import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { Field, Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';

import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { updateFullName } from 'services/auth';

interface Props {
  shouldShow: boolean;
  onHide: () => void;
  fullName: string;
  setUserName: (key: string) => void;
}

export const UpdateNameModal = ({ shouldShow, onHide, fullName, setUserName }: Props): JSX.Element => {
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    newName: Yup.string()
      .required(t('validation.required'))
      .min(2, t('validation.min2Characters'))
      .test('newName', t('updateName.changeYourName'), (newName) => {
        if (newName && newName === fullName) {
          return false;
        }

        return true;
      }),
  });

  return (
    <Modal show={shouldShow} onHide={onHide} id="modal" centered>
      <Modal.Header closeButton className="border-0 pr-4 pl-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{t('text.EditName')}</b>
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{ newName: fullName }}
        validationSchema={Schema}
        onSubmit={({ newName }, { setSubmitting }) => {
          updateFullName({ fullName: newName })
            .then(() => {
              setUserName(newName);
              onHide();
              showNotification(t('text.NameUpdatedMessage'), 'success');
            })
            .catch((errors) => {
              showAllNotifications(parseResponseErrors(errors), 'error');
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Body className="pr-4 pl-4">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('text.FullName')}</Form.Label>
                <Field type="text" name="newName" className="form-control" />
                <ErrorMessage name="newName" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <KudoButton type="submit">
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  t('buttons.Save')
                )}
              </KudoButton>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
