import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'urls';

import { InviteOrganizationUsers } from 'components/inviteOrganizationUsers';
import { Layout } from 'components/shared/Layout';
import { ROLES } from 'constants/index';
import AddOrganization from 'containers/AddOrganization';
import ManageUsers from 'containers/ManageUsers';
import { Organization } from 'containers/Organization';
import PhoneNumberAdder from 'containers/PhoneNumberAdder';
import PhoneNumberVerify from 'containers/PhoneNumberVerify';
import { BaseProfile } from 'containers/Profile';
import { RootState } from 'shared/types/store';

export const AuthenticatedRoutes = (): JSX.Element => {
  const {
    profile: { roles },
  } = useSelector(({ authReducer }: RootState) => authReducer);

  return (
    <Switch>
      <Route exact path={urls.addPhoneNumber} component={PhoneNumberAdder} />
      <Route exact path={urls.addOrganization} component={AddOrganization} />
      <Route exact path={urls.verifyPhoneNumber} component={PhoneNumberVerify} />
      <Route exact path={urls.inviteOrganizationUsers} component={InviteOrganizationUsers} />
      <Route>
        <Layout>
          <Switch>
            <Route exact path={urls.profile} component={BaseProfile} />
            <Route exact path={urls.organizations} component={Organization} />
            {roles?.includes(ROLES.SUPER_ADMIN) ? (
              <Route exact path={urls.manageUsers} component={ManageUsers} />
            ) : null}
            <Redirect to={urls.profile} />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};
