import { useEffect } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getUserOrganizations, SetProfile } from 'redux/actions/authActions';
import urls from 'urls';

import accountImg from 'assets/images/account.svg';
import organizationImg from 'assets/images/icon.svg';
import { ROLES } from 'constants/index';
import { shouldShowOrganizationTab } from 'helpers/authHelpers';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import { showAllNotifications } from 'helpers/showNotification';
import { fetchUserProfile } from 'services/auth';
import { RootState } from 'shared/types/store';

import { Footer } from './Footer';
import Header from './Header';
import { HeapAnalytics } from './HeapAnalytics';

import styles from './Layout.module.scss';

interface Props {
  profilePic?: string;
  children: JSX.Element;
}

export const Layout = ({ profilePic, children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, isAuthenticating, userId, organization, organizations } = useSelector(
    ({ authReducer }: RootState) => authReducer
  );

  useEffect(() => {
    fetchUserProfile()
      .then((response) => {
        dispatch(SetProfile(response.data.body));
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserOrganizations());
  }, [dispatch]);

  return (
    <div className="pb-2">
      <HeapAnalytics userId={userId} organization={organization} />
      <Header name={profile?.fullName} profilePic={profilePic} />
      <div className={styles.layoutContainer}>
        <Row>
          <Col sm={2} className="pr-sm-0">
            <div className={styles.Sidebar} data-testid="loader">
              {isAuthenticating ? (
                <Skeleton width={200} height={40} count={3} className="ml-3" />
              ) : (
                <>
                  <Link
                    className={`${styles.SidebarButton} ${
                      history.location.pathname === urls.profile ? styles.active : ''
                    }`}
                    to={urls.profile}
                  >
                    <img src={accountImg} alt="account" className="mr-3 mb-1" />
                    {t('text.Account')}
                  </Link>
                  {shouldShowOrganizationTab(profile.roles) ? (
                    <>
                      {organizations?.length > 0 && (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Toggle
                            as={Button}
                            className={`${styles.SidebarButton} ${styles.OrganizationsButton} ${styles.organizations} text-left border-0 p-2`}
                            variant="light"
                            eventKey="0"
                          >
                            <img
                              src={organizationImg}
                              alt="organization"
                              className={`${styles.organizationIcon} mr-3 mb-1`}
                            />
                            <span className={styles.accordionFontsize}>{t('text.Organization')}</span>
                          </Accordion.Toggle>

                          <div className="ml-5">
                            {organizations?.map((org, index) => (
                              <Link
                                data-toggle="tooltip"
                                data-placement="top"
                                title={org?.name}
                                className={`${styles.SidebarButton} ${styles.organizationTextWrap} ${
                                  history.location.pathname === urls.generateOrganizationUrl(org.id)
                                    ? styles.active
                                    : ''
                                }`}
                                to={urls.generateOrganizationUrl(org.id)}
                                key={org.id}
                              >
                                <i className={`${styles.sidebarIcon} fas fa-thin fa-building`} />
                                {org.name ? org.name : t('organizations.placeholder', { index: index + 1 })}
                              </Link>
                            ))}
                          </div>
                        </Accordion>
                      )}
                    </>
                  ) : null}
                  {profile.roles?.includes(ROLES.SUPER_ADMIN) ? (
                    <Link
                      className={`${styles.SidebarButton} ${
                        history.location.pathname === urls.manageUsers ? styles.active : ''
                      }`}
                      to={urls.manageUsers}
                    >
                      <img src={organizationImg} alt="bag" className="mr-3 mb-1" />
                      {t('text.ManageUsers')}
                    </Link>
                  ) : null}
                </>
              )}
            </div>
          </Col>
          <Col sm={10} className="pl-sm-0">
            {children}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};
