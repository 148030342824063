import classnames from 'classnames';
import { ErrorMessage as FormikErrorMessage, ErrorMessageProps } from 'formik';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ component = 'span', className, ...props }: ErrorMessageProps): JSX.Element => (
  <FormikErrorMessage {...props} className={classnames(styles.errorText, className)} component={component} />
);

export default ErrorMessage;
