import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import sharedStyles from 'stylesheets/sharedStyles.module.scss';

import { Footer } from './Footer';
import { HeaderWithoutUser } from './HeaderWithoutUser';

const Session = ({ children, className }) => (
  <div className={`user-form ${className}`}>
    <HeaderWithoutUser />
    <Container className={`${sharedStyles.container} body-container`}>
      <Row>
        <Col md="12">{children}</Col>
      </Row>
    </Container>
    <Footer />
  </div>
);

Session.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Session.defaultProps = {
  children: null,
  className: '',
};

export default Session;
