import { ComponentProps, ReactElement } from 'react';
import Dropdown, { DropdownProps } from 'react-bootstrap/Dropdown';

import styles from './DropdownMenu.module.scss';

export const DropdownMenuItem = Dropdown.Item;

type DropdownMenuProps = DropdownProps & {
  children:
    | null
    | ReactElement<ComponentProps<typeof DropdownMenuItem>>
    | (ReactElement<ComponentProps<typeof DropdownMenuItem>> | null)[];
};

export const DropdownMenu = ({ children, ...props }: DropdownMenuProps): JSX.Element => (
  <Dropdown className={styles.Container} {...props}>
    <Dropdown.Toggle variant="transparent">
      <i className="fas fa-ellipsis-v" />
    </Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);
