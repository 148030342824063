import { useCallback, useMemo, useState } from 'react';
import BSButton from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { initiatePasswordChange, SetProfile } from 'redux/actions/authActions';
import urls from 'urls';

import { Button } from 'components/elements/Button';
import ProfilePicture from 'components/shared/ProfilePicture';
import RenderPassword from 'components/shared/RenderPassword';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { toggleMagicLink, toggleMfa } from 'services/auth';
import { RootState } from 'shared/types/store';
import { getFeatureFlags } from 'shared/utils/featureFlags';

import HelpTooltip from './HelpTooltip';
import PhoneNumberModal from './PhoneNumberModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { UpdateEmailModal } from './UpdateEmailModal';
import { UpdateNameModal } from './UpdateNameModal';
import UpdateRegionModal from './UpdateRegionModal';

export const Profile = (): JSX.Element | null => {
  const { t } = useTranslation();
  const [shouldShowEditNameModal, setShouldShowEditNameModal] = useState(false);
  const [shouldShowUpdateEmailModal, setShouldShowUpdateEmailModal] = useState(false);
  const [shouldShowResetPasswordModal, setShouldShowResetPasswordModal] = useState(false);
  const [shouldShowChangeRegionModal, setShouldShowChangeRegionModal] = useState(false);
  const [shouldShowChangePhoneModal, setShouldShowChangePhoneModal] = useState(false);
  const [shouldShowAddPhoneModal, setShouldShowAddPhoneModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingMagicLinkStatus, setIsSubmittingMagicLinkStatus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { profile, clientId, organizations } = useSelector(({ authReducer }: RootState) => authReducer);
  const isDeleteUserEnabled = getFeatureFlags().WithDeleteUser;

  const mfaCheckBoxState = useMemo(() => {
    if (profile.phone?.mfaEnabled) {
      return {
        ticked: true,
        disabled: false,
        tooltip: t('text.MFATooltipText'),
      };
    }
    if (
      !profile.phone?.mfaEnabled &&
      profile.phone?.phone &&
      (profile.isPasswordUpdated || !profile.isMagicLinkUser) &&
      !profile.isMagicLinkEnabled
    ) {
      return {
        ticked: false,
        disabled: false,
        tooltip: t('text.MFATooltipText'),
      };
    }

    return {
      ticked: false,
      disabled: true,
      tooltip: t('text.MFATooltipDisabledText'),
    };
  }, [profile, t]);

  const loginViaLinkCheckBoxState = useMemo(() => {
    if (profile.phone?.mfaEnabled) {
      return {
        ticked: false,
        disabled: true,
        tooltip: t('text.LoginViaLinkTooltipDisabled'),
      };
    }
    if (!profile.isMagicLinkEnabled && !profile.isMagicLinkUser) {
      return {
        ticked: false,
        disabled: false,
        tooltip: t('text.LoginViaPasswordTooltip'),
      };
    }

    if (!profile.isPasswordUpdated && profile.isMagicLinkUser) {
      return {
        ticked: true,
        disabled: true,
        tooltip: t('text.LoginViaPasswordTooltip'),
      };
    }
    if (
      (profile.isMagicLinkEnabled && profile.isPasswordUpdated) ||
      (profile.isMagicLinkEnabled && !profile.isMagicLinkUser)
    ) {
      return {
        ticked: true,
        disabled: false,
        tooltip: t('text.LoginViaLinkTooltip'),
      };
    }

    return {
      ticked: false,
      disabled: false,
      tooltip: t('text.LoginViaLinkTooltip'),
    };
  }, [profile, t]);

  const toggleMagicLinkStatus = useCallback(() => {
    setIsSubmittingMagicLinkStatus(true);
    toggleMagicLink({ enabled: !profile.isMagicLinkEnabled })
      .then(() => {
        if (profile.isMagicLinkEnabled) {
          showNotification(t('text.MagicLinkDisabled'), 'success');
        } else {
          showNotification(t('text.MagicLinkEnabled'), 'success');
        }
        dispatch(
          SetProfile({
            ...profile,
            isMagicLinkEnabled: !profile.isMagicLinkEnabled,
          })
        );
      })
      .catch((errors) => {
        showAllNotifications(parseResponseErrors(errors), 'error');
      })
      .finally(() => setIsSubmittingMagicLinkStatus(false));
  }, [dispatch, profile, t]);

  const toggleMfaStatus = useCallback(() => {
    toggleMfa()
      .then(() => {
        if (profile.phone.mfaEnabled) {
          showNotification(t('text.MultiFactorAuthenticationIsDisabled'), 'success');
        } else {
          showNotification(t('text.MultiFactorAuthenticationIsEnabled'), 'success');
        }
        dispatch(
          SetProfile({
            ...profile,
            phone: { ...profile.phone, mfaEnabled: !profile.phone.mfaEnabled },
          })
        );
      })
      .catch((errors) => {
        showAllNotifications(parseResponseErrors(errors), 'error');
      });
  }, [dispatch, profile, t]);

  const editPassword = useCallback(() => {
    setIsSubmitting(true);
    dispatch(
      initiatePasswordChange(
        {
          clientId,
          email: profile.email,
        },
        setShouldShowResetPasswordModal,
        setIsSubmitting
      )
    );
  }, [clientId, dispatch, profile.email]);

  if (!profile.region) return null;

  return (
    <div className="account-profile">
      <Container className="body-container">
        <Row>
          <Col md="12" className="pl-sm-0 pr-sm-0">
            <div className="profile-avatar">
              <ProfilePicture name={profile.fullName} />
            </div>
            <div className="profile-name">{profile.fullName}</div>
            <div className="user-form-inner">
              <Row>
                <div className="single-line">
                  <span className="heading">{t('text.FullName')}</span>
                  <span className="value">{profile.fullName}</span>
                  <span className="edit">
                    <Button
                      shouldShowIcon
                      iconClass="far fa-pencil"
                      variant="link"
                      onClick={() => setShouldShowEditNameModal(true)}
                      data-testid="UpdateNameModalBtn"
                    />
                  </span>
                </div>
                <UpdateNameModal
                  shouldShow={shouldShowEditNameModal}
                  onHide={() => setShouldShowEditNameModal(false)}
                  fullName={profile.fullName}
                  setUserName={(name) => dispatch(SetProfile({ ...profile, fullName: name }))}
                />
              </Row>
              <hr />
              <Row>
                <div className="single-line">
                  <span className="heading">{t('text.Email')}</span>
                  <span className="value">{profile.email}</span>
                  <span className="edit">
                    <Button
                      shouldShowIcon
                      iconClass="far fa-pencil"
                      variant="link"
                      onClick={() => setShouldShowUpdateEmailModal(true)}
                      data-testid="UpdateEmailModalBtn"
                    />
                  </span>
                </div>
                <UpdateEmailModal
                  shouldShow={shouldShowUpdateEmailModal}
                  onHide={() => setShouldShowUpdateEmailModal(false)}
                  oldEmail={profile.email}
                  setEmail={(email) => dispatch(SetProfile({ ...profile, email }))}
                />
              </Row>
              <hr />
              <Row>
                <div className="single-line">
                  <span className="heading">{t('text.Password')}</span>
                  <span className="value">
                    {profile.isPasswordUpdated || !profile.isMagicLinkUser ? (
                      <span className="password-value">
                        <RenderPassword />
                      </span>
                    ) : (
                      <span className="text-secondary">{t('text.CreatePassword')}</span>
                    )}

                    <span>
                      <Form.Check type="checkbox" className="mt-2">
                        <Form.Check.Input
                          type="checkbox"
                          id="LoginCheckbox"
                          checked={loginViaLinkCheckBoxState.ticked}
                          disabled={loginViaLinkCheckBoxState.disabled || isSubmittingMagicLinkStatus}
                          onClick={toggleMagicLinkStatus}
                        />
                        <Form.Check.Label
                          className={`mr-2 ${loginViaLinkCheckBoxState.disabled ? 'text-secondary' : null}`}
                          htmlFor="LoginCheckbox"
                        >
                          {t('text.LoginViaLink')}
                        </Form.Check.Label>
                        <HelpTooltip text={loginViaLinkCheckBoxState.tooltip} />
                      </Form.Check>
                    </span>
                  </span>
                  <span className="edit">
                    {isSubmitting ? (
                      <BSButton className="submit-btn reset-btn" type="submit">
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      </BSButton>
                    ) : (
                      <Button shouldShowIcon iconClass="far fa-pencil" variant="link" onClick={editPassword} />
                    )}
                  </span>
                </div>
                <ResetPasswordModal
                  data-testid="ResetPasswordModal"
                  shouldShow={shouldShowResetPasswordModal}
                  onHide={() => setShouldShowResetPasswordModal(false)}
                />
              </Row>
              {!profile.isPasswordUpdated && profile.isMagicLinkUser ? null : (
                <>
                  <hr />
                  <Row>
                    <div className="single-line">
                      <span className="heading">{t('text.MobileNumber')}</span>
                      <span className="value">
                        {profile.phone ? (
                          <>
                            <div className="info-control">
                              <p className="number">{`${profile.phone.countryCode} ${profile.phone.phone}`}</p>
                              <div className="mfa-info">
                                <input
                                  name="mfa"
                                  type="checkbox"
                                  data-testid="mfaCheckbox"
                                  checked={mfaCheckBoxState.ticked}
                                  disabled={mfaCheckBoxState.disabled}
                                  onChange={toggleMfaStatus}
                                />
                                <span className="detail mr-2 ml-1">{t('text.TwoFactorAuthentication')}</span>
                                <HelpTooltip text={mfaCheckBoxState.tooltip} />
                                <span className="ml-1 why-this">{t('text.WhatsThis')}</span>
                              </div>
                            </div>
                            {process.env.REACT_APP_USE_MFA_BACKUP_CODES === 'true' ? (
                              <div className="info-control">
                                <Button
                                  variant="link"
                                  className="add-phone-btn my-0 mr-2"
                                  text={t('text.GenerateBackupCodes')}
                                />
                                <span>
                                  <HelpTooltip text={t('text.BackCodesTooltipText')} />
                                  <span className="ml-1 why-this">{t('text.WhyItIsNeeded')}</span>
                                </span>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <div className="info-control">
                            <Button
                              variant="link"
                              className="add-phone-btn my-0 mr-2"
                              text={t('text.AddPhoneNumberInEditProfile')}
                              onClick={() => setShouldShowAddPhoneModal(true)}
                              data-testid="AddPhoneNumberModalBtn"
                            />
                            <p className="phone-info">{t('text.AddPhoneNumberInEditProfileInfo')}</p>
                            <PhoneNumberModal
                              show={shouldShowAddPhoneModal}
                              onHide={() => setShouldShowAddPhoneModal(false)}
                              phone={{
                                phone: '',
                                mfaEnabled: false,
                                countryCode: '',
                              }}
                              setPhone={(phone) => dispatch(SetProfile({ ...profile, phone }))}
                              isUpdate={false}
                            />
                          </div>
                        )}
                      </span>
                      <span className="edit">
                        {profile.phone ? (
                          <>
                            <Button
                              shouldShowIcon
                              iconClass="far fa-pencil"
                              variant="link"
                              className="extra-btn"
                              onClick={() => setShouldShowChangePhoneModal(true)}
                            />
                            <PhoneNumberModal
                              show={shouldShowChangePhoneModal}
                              onHide={() => setShouldShowChangePhoneModal(false)}
                              phone={profile.phone}
                              setPhone={(phone) => dispatch(SetProfile({ ...profile, phone }))}
                              isUpdate
                            />
                          </>
                        ) : null}
                      </span>
                    </div>
                  </Row>
                </>
              )}
              <hr />
              <Row>
                <div className="single-line">
                  <span className="heading">
                    {t('text.DataResidencyRegion')}
                    <div className="info-control info-margin-top">
                      <HelpTooltip text={t('text.DataResidencyTooltipText')} />
                      <span className="why-this ml-1">{t('text.WhatsThis')}</span>
                    </div>
                  </span>
                  <span className="value">{profile.region.name}</span>
                  <span className="edit">
                    <Button
                      shouldShowIcon
                      iconClass="far fa-pencil"
                      variant="link"
                      onClick={() => setShouldShowChangeRegionModal(true)}
                    />
                  </span>
                </div>
                <UpdateRegionModal
                  show={shouldShowChangeRegionModal}
                  data-testid="UpdateRegionModal"
                  onHide={() => setShouldShowChangeRegionModal(false)}
                  currentRegion={profile.region.value}
                  setRegion={(region) => dispatch(SetProfile({ ...profile, region }))}
                />
              </Row>
              {organizations[0]?.aiuser ? (
                <>
                  <hr />
                  <Row>
                    <div className="single-line">
                      <span className="heading">{t('text.CancelSubscription')}</span>
                      <span className="edit value">
                        <a
                          data-testid="cancel_stripe"
                          href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_LINK}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Cancel
                        </a>
                      </span>
                    </div>
                  </Row>
                </>
              ) : null}
            </div>
            {isDeleteUserEnabled ? (
              <div className="text-center">
                <Link to={`${urls.deleteAccount}?userId=${profile.userId}`}>{t('buttons.DeleteAccount')}</Link>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
