import baseService from './baseService';

export function signUp(params) {
  return baseService.post('/users/registration', params);
}

export function login(body, params) {
  return baseService.post('/login', body, { params });
}

export function forgetPassword(params) {
  return baseService.post('/forgot-password/code-generation', params);
}

export function setPassword(params) {
  return baseService.post('/forgot-password/code-confirmation', params);
}

export function addPhoneNumber(params) {
  return baseService.post('/users/phone', params);
}

export function verifyPhoneNumber(body, params) {
  return baseService.post('/users/phone/verification', body, { params });
}

export function skipPhoneNumberAddition(body, params) {
  return baseService.post('/login/skip', body, { params });
}

export const getAvailableRegions = async () => baseService.get('/configurations/regions');

export function mfaLogin(body, params) {
  return baseService.post('/mfa/login', body, { params });
}

export function resendVerificationCode() {
  return baseService.get('/users/phone/resend-code');
}

export function fetchUserProfile() {
  return baseService.get('/users/profile');
}

export function fetchUserOrganizations() {
  return baseService.get('/user/organizations');
}

export const fetchUserSession = (clientId, userId, redirectUri) =>
  baseService.get(`users/${userId}/session?clientId=${clientId}&redirectUri=${redirectUri}`);

export const updateFullName = (params) => baseService.put('/users/profile/full-name', params);

export const updateEmail = (params) => baseService.post('/users/profile/email', params);

export const confirmEmail = (params) => baseService.post('/users/email/verification', params);

export const resetPassword = (params) => baseService.post('/password/reset', params);

export const changeRegion = (params) => baseService.put('/users/profile/region', params);

export const verifyUpdatedPhoneNumber = (params) => baseService.post('/users/profile/phone/verification', params);

export const addTheOrganization = (params) => baseService.post('/organization', params);

export const toggleMfa = () => baseService.put('/users/profile/mfa', {});

export const toggleMagicLink = (params) => baseService.put('/user/profile/magic-link', params);

export const editOrganization = (id, params) => baseService.put(`/organization/${id}`, params);

export const getUserByEmail = (params) => baseService.post('/users/profile/search', params);

export const getRoles = () => baseService.get('/roles');

export const inviteUser = (params) => baseService.post('/users/invitation', params);

export const authorizeUser = (params) => baseService.post('/web/token/code-grant', params);

export const verifyUser = (params) => baseService.post('/magic-link/verification', params);

export const magicLinkLogin = (params) => baseService.post('/magic-link/login', params);

export const magicLinkSignup = (params) => baseService.post('/magic-link/registration', params);

export const getOrganizationMembers = (organizationId) => baseService.get(`/organizations/${organizationId}/members`);

export const inviteOrgUsers = (organizationId, params) =>
  baseService.post(`/organizations/${organizationId}/members/invitation`, params);

export const acceptOrganizationInvitation = (invitationId) =>
  baseService.post('/invitation/acceptance', { invitationId });

export const setOrganizationMemberRoles = (organizationId, params) =>
  baseService.post(`/organization/${organizationId}/member/roles`, params);

export const removeOrganizationMember = (organizationId, userId) =>
  baseService.delete(`/organizations/${organizationId}/members/${userId}`);

export const removeOrgMemberByInviteId = (organizationId, invitationId) =>
  baseService.delete(`/organizations/${organizationId}/invitations/${invitationId}`);

export const fetchUserDeletionInformation = () => baseService.get(`/users/deletable`);

export const deleteUser = (userId) => baseService.delete(`users/${userId}/deletion`);
