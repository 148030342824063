import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SadFace } from 'assets/images/sad-face.svg';
import { Footer } from 'components/shared/Footer';
import { HeaderWithoutUser } from 'components/shared/HeaderWithoutUser';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';
import sharedStyles from 'stylesheets/sharedStyles.module.scss';

import styles from './AccountDeactivated.module.scss';

const AccountDeactivated = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.AccountDeactivated} user-form`}>
      <HeapAnalytics />
      <HeaderWithoutUser />
      <Container className={`${sharedStyles.container} body-container`}>
        <Row>
          <Col md={12}>
            <div className={styles.Card}>
              <SadFace aria-label={t('alt.SadFace')} className={styles.SadFace} />
              <p className={styles.Heading}>{t('headings.AccountDeactivated')}</p>
              <p className={styles.Text}>{t('text.AccountDeactivated')}</p>
              <p className={styles.Text}>
                {t('text.AccountDeactivatedExtraInfo')}
                <br />
                <a className={styles.Email} href="mailto:support@kudoway.com">
                  Support@kudoway.com
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export { AccountDeactivated };
