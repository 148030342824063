import { toast } from 'react-toastify';

import { ReactComponent as successCheck } from 'assets/images/circle-check-solid.svg';

const iconsObject = {
  success: successCheck,
};

const showNotification = (message, type) => {
  toast(message, {
    position: 'top-center',
    icon: iconsObject[type],
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type,
    progress: undefined,
  });
};

export const showAllNotifications = (notifications, type) => {
  notifications.forEach((notification) => showNotification(notification, type));
};

export default showNotification;
