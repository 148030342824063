import React from 'react';

import AddOrg from 'components/addOrganization/AddOrganization';
import { HeapAnalytics } from 'components/shared/HeapAnalytics';

const AddOrganization = () => (
  <>
    <HeapAnalytics />
    <AddOrg />
  </>
);

export default AddOrganization;
