import i18n from 'i18next';
import { createAction } from 'redux-actions';

import { SignOutSuccess } from 'services/baseService';

import { parseResponseErrors } from '../../helpers/notificationHelpers';
import showNotification, { showAllNotifications } from '../../helpers/showNotification';
import {
  acceptOrganizationInvitation as acceptOrganizationInvitationApi,
  addTheOrganization,
  authorizeUser,
  deleteUser,
  editOrganization,
  fetchUserDeletionInformation,
  fetchUserOrganizations,
  fetchUserSession,
  forgetPassword,
  getOrganizationMembers,
  getRoles,
  getUserByEmail,
  inviteOrgUsers,
  inviteUser,
  login as loginAPI,
  magicLinkLogin,
  magicLinkSignup,
  removeOrganizationMember,
  removeOrgMemberByInviteId,
  setOrganizationMemberRoles as setOrganizationMemberRolesApi,
  verifyUser,
} from '../../services/auth';
import * as constants from '../constants/authConstants';

export const SetLogin = createAction(constants.SET_LOGIN);
export const SetProfile = createAction(constants.SET_PROFILE);
export const SetAuthenticating = createAction(constants.SET_AUTHENTICATING);
export const SetOrganization = createAction(constants.SET_ORGANIZATION);
export const SetMembers = createAction(constants.SET_MEMBERS);
export const SetOrganizations = createAction(constants.SET_ORGANIZATIONS);
export const UpdateOrganization = createAction(constants.UPDATE_ORGANIZATION);
export const RemoveMember = createAction(constants.REMOVE_MEMBER);

export const login = (body, params, handleNextStep, setSubmitting, onError) => (dispatch) =>
  loginAPI(body, params)
    .then((response) => {
      dispatch(
        SetLogin({ userId: response.data.body.userId, clientId: params.clientId, redirectUri: params.redirectUri })
      );
      handleNextStep(response.data.body);
    })
    .catch((error) => {
      dispatch(SetLogin({ userId: null, clientId: null }));
      onError(parseResponseErrors(error));
    })
    .finally(() => setSubmitting(false));

export const addOrganization = (params, setSubmitting) => () =>
  addTheOrganization(params)
    .then((response) => {
      window.location.href = response.data.flow.details.redirectUri;
    })
    .catch((error) => {
      showAllNotifications(parseResponseErrors(error), 'error');
    })
    .finally(() => setSubmitting(false));

export const editUserOrganization =
  ({ organizationId, organization, onHide, setSubmitting }) =>
  (dispatch) =>
    editOrganization(organizationId, organization)
      .then(() => {
        dispatch(UpdateOrganization({ ...organization, id: organizationId }));
        onHide();
        showNotification(i18n.t('text.OrganizationUpdatedMessage'), 'success');
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      })
      .finally(() => setSubmitting(false));

export const fetchUserByEmail =
  ({ values, setUser }) =>
  () =>
    getUserByEmail(values)
      .then((response) => {
        setUser(response.data.body);
      })
      .catch(() => {
        setUser(null);
      });

export const fetchRoles = (setAvailableRoles) => () =>
  getRoles()
    .then((response) => {
      setAvailableRoles(
        response.data.body.roles.map((role) => ({
          label: role.displayName,
          value: role.name,
        }))
      );
    })
    .catch((error) => {
      showAllNotifications(parseResponseErrors(error), 'error');
    });

export const authorizeRole = (params, updateUrl) => (dispatch) => {
  dispatch(SetAuthenticating(true));

  return authorizeUser(params)
    .catch((error) => showAllNotifications(parseResponseErrors(error), 'error'))
    .finally(() => {
      updateUrl();
      dispatch(SetAuthenticating(false));
    });
};

export const inviteNewUser =
  ({ payload, setIsSubmitting, doesProfileExist, setUser, setEmail, setErrors }) =>
  () =>
    inviteUser(payload)
      .then(() => {
        if (doesProfileExist) {
          showNotification(i18n.t('notifications.UserUpdatedSuccessfully'), 'success');
          setUser(null);
          setEmail('');
        } else {
          showNotification(i18n.t('notifications.UserInvitedSuccessfully'), 'success');
        }
      })
      .catch((error) => {
        const { response: { data: { errors: responseErrors } = {} } = {} } = error || {};

        setErrors(responseErrors.reduce((old, err) => ({ ...old, [err.subject]: err.message }), {}));

        return showAllNotifications(parseResponseErrors(error), 'error');
      })
      .finally(() => setIsSubmitting(false));

export const verifyMagicLink = (params, handleNextStep, redirectToLogin) => (dispatch) =>
  verifyUser(params)
    .then((response) => {
      dispatch(SetLogin({ userId: response.data.userId, clientId: params.clientId, redirectUri: params.redirectUri }));
      handleNextStep(response.data);
    })
    .catch((error) => {
      dispatch(SetLogin({ userId: null, clientId: null }));
      redirectToLogin();
      showAllNotifications(parseResponseErrors(error), 'error');
    });

export const loginWithMagicLink = (params, setSubmitting, onSuccess, onError) => () =>
  magicLinkLogin(params)
    .then((response) => {
      onSuccess(response.data.body.flow);
    })
    .catch((error) => {
      onError(parseResponseErrors(error));
    })
    .finally(() => setSubmitting(false));

export const signupWithMagicLink = (params, onSuccess, setSubmitting) => () =>
  magicLinkSignup(params)
    .then(() => onSuccess(params.email))
    .catch((error) => showAllNotifications(parseResponseErrors(error), 'error'))
    .finally(() => setSubmitting(false));

export const initiatePasswordChange = (params, setShouldShowResetPasswordModal, setIsSubmitting) => () =>
  forgetPassword(params)
    .then(() => {
      setShouldShowResetPasswordModal(true);
    })
    .catch((error) => {
      setShouldShowResetPasswordModal(true);
      showAllNotifications(parseResponseErrors(error), 'error');
    })
    .finally(() => setIsSubmitting(false));

/**
 * @param {string} organizationId
 * @param {Object} params
 * @param {("org_member"|"org_admin")[]} params.roles
 * @param {string[]} params.emails
 */
export const inviteOrganizationUsers = async (
  organizationId,
  params,
  setIsSubmitting,
  redirect,
  setFailedInvites,
  setShouldShowFailureModal,
  setShouldRedirectToOrg
) => {
  try {
    const response = await inviteOrgUsers(organizationId, params);

    if (response.data.body.unprocessableEmails) {
      if (response.data.body.unprocessableEmails.length < params.emails.length) {
        setShouldRedirectToOrg(true);
      }
      setFailedInvites(response.data.body.unprocessableEmails);
      setShouldShowFailureModal(true);
    } else {
      showNotification(i18n.t('inviteOrganizationUsers.toast.success'), 'success');
      redirect();
    }
  } catch (error) {
    showAllNotifications(parseResponseErrors(error), 'error');
    throw error;
  } finally {
    setIsSubmitting(false);
  }
};

/**
 * @typedef {Object} AcceptOrgInviteFlowDetails
 * @property {string} url
 * @property {string} email
 * @property {string} clientId
 * @property {string} redirectUri
 * @property {string} isMagicLinkEnabled
 */

/**
 * @typedef {Object} AcceptOrgInviteFlow
 * @property {"SIGNUP_AFTER_INVITE" | "REQUEST_MAGIC_LINK" | "REDIRECT" | "ORG_DISABLED" | "INVITATION_ACCEPTED" | "SHOW_PASSWORD"} nextStep
 * @property {AcceptOrgInviteFlowDetails} details
 */

/**
 * @param {string} invitationId Invitation id.
 * @returns {Promise<AcceptOrgInviteFlow>}
 */
export const acceptOrganizationInvitation = async (invitationId) => {
  try {
    const { flow } = (await acceptOrganizationInvitationApi(invitationId)).data.body;

    return flow;
  } catch (error) {
    showAllNotifications(parseResponseErrors(error), 'error');
    throw error;
  }
};

/**
 * @typedef {Object} RoleItem
 * @property {string} userId
 * @property {string[]} roles
 */

/**
 * @param {string} organizationId Organization ID
 * @param {RoleItem[]} roles Roles array
 */
export const setOrganizationMemberRoles = async (organizationId, roles) => {
  try {
    await setOrganizationMemberRolesApi(organizationId, { roles });
  } catch (error) {
    showAllNotifications(parseResponseErrors(error), 'error');
    throw error;
  }
};

export const getUserOrganizations = () => (dispatch) => {
  fetchUserOrganizations()
    .then((response) => {
      dispatch(SetOrganizations(response.data.body));
    })
    .catch((error) => {
      dispatch(SetOrganizations([]));
      showAllNotifications(parseResponseErrors(error), 'error');
    });
};

export const getOrgMembers = (organizationId, setAreMembersLoading) => (dispatch) =>
  getOrganizationMembers(organizationId)
    .then((response) => {
      dispatch(SetMembers(response.data.body.members));
    })
    .catch((error) => {
      dispatch(SetMembers([]));
      showAllNotifications(parseResponseErrors(error), 'error');
    })
    .finally(() => setAreMembersLoading(false));

export const removeOrgMember = (organizationId, orgName, userId, userName, setIsLoading, invitationId) => (dispatch) =>
  removeOrganizationMember(organizationId, userId)
    .then(() => {
      dispatch(RemoveMember(invitationId));
      showNotification(i18n.t('organization.removeMember.success', { orgMember: userName, orgName }), 'success');
    })
    .catch((error) => {
      showAllNotifications(parseResponseErrors(error), 'error');
    })
    .finally(() => setIsLoading(false));

export const removeOrgMemberWithoutUserId =
  (organizationId, orgName, email, setIsLoading, invitationId) => (dispatch) =>
    removeOrgMemberByInviteId(organizationId, invitationId)
      .then(() => {
        dispatch(RemoveMember(invitationId));
        showNotification(i18n.t('organization.removeMember.success', { orgMember: email, orgName }), 'success');
      })
      .catch((error) => {
        showAllNotifications(parseResponseErrors(error), 'error');
      })
      .finally(() => setIsLoading(false));

export const initiateUserDeletion = (setIsPending, setIsDeletable, setOrgs, setIsError) => (dispatch) =>
  fetchUserDeletionInformation()
    .then((response) => {
      setIsDeletable(response.data.body.deletable);
      setOrgs(response.data.body.organizations);
    })
    .catch((error) => {
      showAllNotifications(parseResponseErrors(error), 'error');
      setIsError(true);
    })
    .finally(() => setIsPending(false));

export const deleteAccount = (userId, setIsSubmitting, handleDelete) => (dispatch) => {
  deleteUser(userId)
    .then(() => {
      handleDelete();
      dispatch(SignOutSuccess());
      setIsSubmitting(false);
    })
    .catch((error) => {
      showAllNotifications(parseResponseErrors(error), 'error');
    })
    .finally(() => setIsSubmitting(false));
};

export const getUserSessionInfo = (clientId, userId, redirectUri, handleNextStep, setIsLoading) => (dispatch) =>
  fetchUserSession(clientId, userId, redirectUri)
    .then((response) => {
      handleNextStep(response.data.body);
    })
    .catch((error) => {
      showNotification(parseResponseErrors(error), 'error');
      setIsLoading(false);
    });
