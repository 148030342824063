import URLs from 'urls';

const kmpClientId = process.env.REACT_APP_KMP_CLIENT_ID;
const kmpRedirectUri = process.env.REACT_APP_KMP_REDIRECT_URI;

export function getLoginUrl(email: string, shouldShowPasswordField?: boolean): string {
  const searchParams = new URLSearchParams();

  searchParams.append('redirectUri', kmpRedirectUri ?? '');
  searchParams.append('clientId', kmpClientId ?? '');
  searchParams.append('email', email);
  if (shouldShowPasswordField) searchParams.append('showPassword', 'true');

  return `${URLs.login}?${searchParams.toString()}`;
}
export function getSignupUrl(email: string, invitationId: string): string {
  const searchParams = new URLSearchParams();

  searchParams.append('email', email);
  searchParams.append('invitationId', invitationId);
  searchParams.append('redirectUri', kmpRedirectUri ?? '');
  searchParams.append('clientId', kmpClientId ?? '');

  return `${URLs.signup}?${searchParams.toString()}`;
}

export function getOrgDisabledSignupUrl(clientid: string, redirectUri: string): string {
  const searchParams = new URLSearchParams();

  searchParams.append('clientId', clientid ?? '');
  searchParams.append('redirectUri', redirectUri ?? '');

  return `${URLs.organizationDeactivated}?${searchParams.toString()}`;
}
