import { FocusEvent, useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import { editUserOrganization } from 'redux/actions/authActions';
import * as Yup from 'yup';

import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import { Organization } from 'shared/types/organization';

interface Props {
  shouldShow: boolean;
  onHide: () => void;
  organization: Organization;
}

const WEBSITE_PREFIX_REGEX = /^https?:\/\/.+/i;
// eslint-disable-next-line prettier/prettier
const HTML_REGEX = /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g;

const Schema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t('organizations.validation.requiredField'))
    .min(2, i18n.t('organizations.validation.textIsShort'))
    .nullable(true)
    .test('name', i18n.t('validation.invalidValue'), (name) => {
      if (name && HTML_REGEX.test(name)) {
        return false;
      }

      return true;
    }),
  website: Yup.string()
    .nullable(true)
    .test('websitelink', i18n.t('organization.invalidDomain'), (website) => {
      if (!website) {
        return true;
      }

      return WEBSITE_PREFIX_REGEX.test(website);
    }),
});

export const UpdateOrganizationModal = ({ shouldShow, onHide, organization }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isRequired, setIsRequired] = useState(true);

  const handleOnHide = useCallback(() => {
    onHide();
    setIsRequired(true);
  }, [onHide]);

  const initialValues = {
    name: organization.name,
    website: organization.website,
  };

  return (
    <Modal show={shouldShow} onHide={handleOnHide} id="modal" centered>
      <Modal.Header closeButton className="border-0 pr-4 pl-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>{t('text.EditOrganization')}</b>
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(
            editUserOrganization({
              organizationId: organization.id,
              organization: values,
              onHide,
              setSubmitting,
            })
          );
        }}
      >
        {({ values, isSubmitting, handleBlur, setFieldValue }) => (
          <FormikForm>
            <Modal.Body className="pr-4 pl-4">
              <Form.Group controlId="formBasicOrgName">
                <Form.Label htmlFor="name" className="d-inline-flex">
                  {t('text.OrganizationName')}
                  {isRequired ? <span className="text-danger ml-2">*</span> : null}
                </Form.Label>
                <Field type="text" name="name" className="form-control" id="name" />
                <ErrorMessage name="name" />
              </Form.Group>
              <Form.Group controlId="formBasicOrgDomain">
                <Form.Label htmlFor="website">{t('text.OrganizationDomain')}</Form.Label>
                <Field
                  type="text"
                  name="website"
                  className="form-control"
                  id="website"
                  placeholder="https://"
                  onFocus={(e: FocusEvent<HTMLInputElement>) => {
                    if (!e.target.value) {
                      setFieldValue('website', 'https://');
                    }
                  }}
                  onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    handleBlur(e);
                    if (e.target.value === 'https://') {
                      setFieldValue('website', '');
                    }
                  }}
                />
                <ErrorMessage name="website" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <KudoButton type="submit" onClick={() => setIsRequired(false)}>
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  t('buttons.Save')
                )}
              </KudoButton>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
