import { useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { Field, Form as FormikForm, Formik } from 'formik';
import i18n from 'i18n';
import * as Yup from 'yup';

import { Button } from 'components/elements/Button';
import KudoButton from 'components/elements/facelift/Button';
import ErrorMessage from 'components/shared/ErrorMessage';
import styles from 'components/shared/ErrorMessage.module.scss';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { confirmEmail, updateEmail } from 'services/auth';

interface Props {
  shouldShow: boolean;
  onHide: () => void;
  oldEmail: string;
  setEmail: (key: string) => void;
}

enum STEP {
  EMAIL = 'email',
  CODE = 'code',
}

const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18n.t('validation.required')),
});

export const UpdateEmailModal = ({ shouldShow, onHide, oldEmail, setEmail }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEP.EMAIL);
  const [error, setError] = useState(null);

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email('Invalid email')
      .test('newEmail', t('updateEmail.changeYourEmail'), (email) => {
        if (email && email === oldEmail) {
          return false;
        }

        return true;
      }),
  });

  const initialValues = {
    email: oldEmail,
    code: '',
  };

  const handleOnHide = useCallback(() => {
    setStep(STEP.EMAIL);
    onHide();
  }, [onHide]);

  const handleResendCode = useCallback(
    (email) => {
      updateEmail({ email })
        .then(() => {
          showNotification(t('text.NewVerificationCodeSentToEmail'), 'success');
        })
        .catch((errors) => {
          showAllNotifications(parseResponseErrors(errors), 'error');
        });
    },
    [t]
  );

  return (
    <Modal
      show={shouldShow}
      onHide={handleOnHide}
      backdrop={step === STEP.CODE ? 'static' : true}
      keyboard={false}
      id="modal"
      centered
      className="email-update-modal"
    >
      <Modal.Header closeButton className="border-0 pr-4 pl-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>
            {step === STEP.EMAIL ? t('text.UpdateEmail') : null}
            {step === STEP.CODE ? t('text.ConfirmYourEmail') : null}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={step === STEP.EMAIL ? EmailSchema : CodeSchema}
        onSubmit={({ email, code }, { setSubmitting }) => {
          if (step === STEP.EMAIL) {
            updateEmail({ email })
              .then(() => {
                setStep(STEP.CODE);
              })
              .catch((errors) => {
                showAllNotifications(parseResponseErrors(errors), 'error');
              })
              .finally(() => setSubmitting(false));
          } else {
            const params = { verificationCode: code };

            confirmEmail(params)
              .then(() => {
                setEmail(email);
                showNotification(t('text.EmailUpdatedMessage'), 'success');
                handleOnHide();
              })
              .catch(() => {
                setError(t('text.VerificationCodeInvalid'));
              })
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <FormikForm>
            {step === STEP.EMAIL ? (
              <Modal.Body className="pr-4 pl-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t('text.Email')}</Form.Label>
                  <Field type="text" className="form-control" name="email" />
                  <ErrorMessage name="email" />
                </Form.Group>
                <span className="alerting-text">{t('text.WillSendVerificationEmail')}</span>
              </Modal.Body>
            ) : null}
            {step === STEP.CODE ? (
              <Modal.Body className="pr-4 pl-4">
                <span className="small-light-text">{t('text.Enter6DigitCode', { target: values.email })}</span>
                <Form.Group controlId="formBasicConfirmationCode" className="mt-4">
                  <Form.Label>{t('text.VerificationCode')}</Form.Label>
                  <Field name="code" className="form-control" />
                </Form.Group>
                <ErrorMessage name="code" />
                {error ? <p className={styles.errorText}>{t('text.VerificationCodeInvalid')}</p> : null}
                <p className="did-not-get-code">
                  {t('text.DidNotGetCode')}
                  <Button
                    text={t('buttons.ResendCode')}
                    variant="link"
                    className="resend-btn"
                    onClick={() => handleResendCode(values.email)}
                  />
                </p>
              </Modal.Body>
            ) : null}
            <Modal.Footer>
              <KudoButton variant="primary" type="submit" data-testid="EmailSaveBtn">
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <>{step === STEP.EMAIL ? t('buttons.Save') : t('buttons.Confirm')}</>
                )}
              </KudoButton>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
