import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Input, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Button';
import KudoButton from 'components/elements/facelift/Button';
import { parseResponseErrors } from 'helpers/notificationHelpers';
import showNotification, { showAllNotifications } from 'helpers/showNotification';
import { addPhoneNumber, resendVerificationCode, verifyUpdatedPhoneNumber } from 'services/auth';

const PhoneNumberModal = ({ show, onHide, phone, setPhone, isUpdate, clientId }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState('phone');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState(`${phone.countryCode}${phone.phone}`);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [unChangedError, setUnChangedError] = useState(null);
  const [countries] = useState(getCountries());
  const [verificationCode, setVerificationCode] = useState('');

  const currentCountryCode = useMemo(
    () => countries.find((c) => getCountryCallingCode(c) === phone.countryCode.substr(1)),
    [countries, phone.countryCode]
  );

  useEffect(() => {
    if (isUpdate) {
      setCountry(currentCountryCode);
    }
  }, [isUpdate, currentCountryCode]);

  const updateUserPhoneNumber = useCallback(() => {
    if (`${phone.countryCode}${phone.phone}` === phoneNumber) {
      setUnChangedError(t('text.PhoneNumberIsUnchanged'));

      return;
    }

    setIsSubmitting(true);
    const params = {
      clientId,
      countryCode: `+${getCountryCallingCode(country)}`,
      phoneNumber: phoneNumber.slice(getCountryCallingCode(country).length + 1, phoneNumber.length),
      mfaEnabled: phone.mfaEnabled,
    };

    addPhoneNumber(params)
      .then(() => {
        setStep('code');
      })
      .catch((errors) => {
        showAllNotifications(parseResponseErrors(errors), 'error');
      })
      .finally(() => setIsSubmitting(false));
  }, [clientId, country, phone.countryCode, phone.mfaEnabled, phone.phone, phoneNumber, t]);

  const handleOnHide = useCallback(() => {
    setStep('phone');
    setPhoneNumber(`${phone.countryCode}${phone.phone}`);
    setCountry(currentCountryCode);
    setUnChangedError(null);
    onHide();
  }, [currentCountryCode, onHide, phone.countryCode, phone.phone]);

  const verifyPhoneNumber = useCallback(() => {
    setIsSubmitting(true);
    const params = { verificationCode };

    verifyUpdatedPhoneNumber(params)
      .then(() => {
        setPhone({
          countryCode: `+${getCountryCallingCode(country)}`,
          phone: phoneNumber.slice(getCountryCallingCode(country).length + 1, phoneNumber.length),
          mfaEnabled: phone.mfaEnabled,
        });
        showNotification(t('text.PhoneNumberUpdatedMessage'), 'success');
        onHide();
        setStep('phone');
        setVerificationCode('');
      })
      .catch(() => {
        showNotification(t('text.VerificationCodeInvalid'), 'error');
      })
      .finally(() => setIsSubmitting(false));
  }, [verificationCode, setPhone, country, phoneNumber, phone.mfaEnabled, t, onHide]);

  const handleResendCode = useCallback(() => {
    resendVerificationCode().then(() => {
      showNotification(t('text.NewVerificationCodeSent'), 'success');
    });
  }, [t]);

  const onCountryChange = useCallback((e) => {
    setCountry(e.target.value);
    setPhoneNumber('');
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      backdrop={step === 'code' ? 'static' : true}
      keyboard={false}
      id="modal"
      centered
      className="phone-update-modal"
    >
      <Modal.Header closeButton className="border-0 pr-4 pl-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <b>
            {step === 'phone' ? <>{isUpdate ? t('text.UpdateMobileNumber') : t('text.AddMobileNumber')}</> : null}
            {step === 'code' ? t('text.VerifyPhoneNumber') : null}
          </b>
        </Modal.Title>
      </Modal.Header>
      {step === 'phone' ? (
        <Modal.Body className="pr-4 pl-4">
          <Form.Group controlId="formBasicCountry">
            <Form.Label>{t('text.Country')}</Form.Label>
            <select
              className="form-control select"
              value={country}
              onChange={onCountryChange}
              required
              data-testid="select-country"
            >
              <option value="">{en['.ZZ']}</option>
              {countries.map((countryName) => (
                <option key={countryName} value={countryName}>
                  {en[countryName]}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group controlId="formBasicPhoneNumber">
            <Form.Label>{t('text.MobileNumber')}</Form.Label>
            <Input
              name="mobileNumber"
              className="form-control"
              withCountryCallingCode
              international
              country={country}
              value={phoneNumber}
              data-testid="phone-input"
              onChange={(e) => {
                setPhoneNumber(e || '');
                setUnChangedError(null);
                if (isPhoneValid) setIsPhoneValid(false);
              }}
            />
            {(phoneNumber || isPhoneValid) && !isValidPhoneNumber(phoneNumber) && (
              <p className="text-danger text-left phone-num-validate">{t('text.PhoneNumberUnrecognized')}</p>
            )}
            {unChangedError ? <p className="text-danger phone-num-validate">{unChangedError}</p> : null}
          </Form.Group>
          <span className="alerting-text">{t('text.NumberConfirmationText')}</span>
        </Modal.Body>
      ) : null}
      {step === 'code' ? (
        <Modal.Body className="pr-4 pl-4">
          <span className="small-light-text">
            {t('text.Enter6DigitCode', {
              target: `(+${getCountryCallingCode(country)}) *** *** **${phoneNumber.slice(-2)}`,
            })}
          </span>
          <Form.Group controlId="formBasicConfirmationCode" className="mt-4">
            <Form.Label>{t('text.VerificationCode')}</Form.Label>
            <Form.Control type="text" onChange={(e) => setVerificationCode(e.target.value)} value={verificationCode} />
          </Form.Group>
          <p className="did-not-get-code">
            {t('text.DidNotGetCode')}
            <Button text={t('buttons.ResendCode')} variant="link" className="resend-btn" onClick={handleResendCode} />
          </p>
          <Button
            variant="link"
            className="resend-btn pl-0"
            text={t('buttons.UseDifferentNumber')}
            onClick={() => setStep('phone')}
          />
        </Modal.Body>
      ) : null}
      <Modal.Footer>
        {isSubmitting ? (
          <KudoButton variant="primary">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </KudoButton>
        ) : (
          <>
            {step === 'phone' ? (
              <KudoButton onClick={updateUserPhoneNumber} disabled={!phoneNumber || !isValidPhoneNumber(phoneNumber)}>
                {t('buttons.Save')}
              </KudoButton>
            ) : null}
            {step === 'code' ? (
              <KudoButton onClick={verifyPhoneNumber} data-testid="verify-btn">
                {t('buttons.Verify')}
              </KudoButton>
            ) : null}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

PhoneNumberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  phone: PropTypes.shape({
    phone: PropTypes.string,
    countryCode: PropTypes.string,
    mfaEnabled: PropTypes.bool,
  }).isRequired,
  setPhone: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  clientId: state.authReducer.clientId,
});

export default connect(mapStateToProps)(PhoneNumberModal);
