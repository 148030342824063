import { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select, { MultiValue } from 'react-select';

import KudoButton from 'components/elements/facelift/Button';
import { Role } from 'shared/types/role';
import { User } from 'shared/types/user';

import { fetchRoles, fetchUserByEmail, inviteNewUser } from '../../redux/actions/authActions';

import styles from './Users.module.scss';

export interface ErrorsType {
  email?: string;
  fullName?: string;
  roles?: string;
}

const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>('');
  const [errors, setErrors] = useState<ErrorsType>({});
  const [fullName, setFullName] = useState<string | undefined>('');
  const [user, setUser] = useState<User | null>();
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [currentRoles, setCurrentRoles] = useState<MultiValue<Role>>([]);
  const [doesProfileExist, setDoesProfileExist] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles(setAvailableRoles));
  }, [dispatch]);

  const fetchUserOnBlur = useCallback(
    (e) => {
      if (e.target.value) {
        dispatch(fetchUserByEmail({ values: { email: e.target.value.trim() }, setUser }));
      }
    },
    [dispatch]
  );

  const handleEmailChange = useCallback(
    (e) => {
      setErrors({});
      setEmail(e.target.value);
      if (doesProfileExist) {
        setUser(null);
      }
    },
    [doesProfileExist]
  );

  const clearForm = useCallback(() => {
    setEmail('');
    setFullName('');
    setCurrentRoles([]);
    setUser(null);
    setErrors({});
  }, []);

  const inviteUser = useCallback(
    (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      const payload = {
        email,
        fullName,
        roles: currentRoles.map((currentRole) => currentRole.value),
      };

      dispatch(inviteNewUser({ payload, setIsSubmitting, doesProfileExist, setUser, setEmail, setErrors }));
    },
    [dispatch, email, fullName, currentRoles, doesProfileExist]
  );

  useEffect(() => {
    if (user) {
      setDoesProfileExist(true);
      setFullName(user.fullName);
      if (Array.isArray(user.roles)) {
        const takenRoles = user.roles.map(
          (role) => availableRoles.find((availableRole) => availableRole.value === role) || { name: role, value: role }
        );

        setCurrentRoles(takenRoles);
      }
    } else {
      setDoesProfileExist(false);
      setFullName('');
      setCurrentRoles([]);
    }
  }, [availableRoles, user]);

  return (
    <div className="account-profile">
      <Container className="body-container">
        <Form onSubmit={inviteUser}>
          <Row>
            <Col md="12" className="pl-sm-0 pr-sm-0">
              <div className="profile-name">{t('text.InviteAUser')}</div>
              <div className={`${styles.formWidth} user-form-inner`}>
                <Form.Group controlId="formBasicEmail" className="mb-4">
                  <Form.Label>{t('text.Email')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={fetchUserOnBlur}
                  />
                  {errors.email && <span className={styles.errorText}>*</span>}
                </Form.Group>

                <Form.Group controlId="formBasicFullName" className="mb-4">
                  <Form.Label>{t('text.FullName')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="full-name"
                    className="form-control"
                    value={fullName}
                    disabled={doesProfileExist}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {errors.fullName && <span className={styles.errorText}>*</span>}
                </Form.Group>

                <Form.Group controlId="formBasicRole" className="mb-4">
                  <Form.Label>{t('text.AddRole')}</Form.Label>
                  <Select
                    className="text-left"
                    isMulti
                    options={availableRoles}
                    value={currentRoles}
                    onChange={(selectedRoles) => setCurrentRoles(selectedRoles)}
                  />
                  {errors.roles && <span className={styles.errorText}>*</span>}
                </Form.Group>
                <div className={`${styles.alignButtons} mb-4`}>
                  <KudoButton variant="outline-danger" className="ml-1 mr-2" onClick={clearForm}>
                    {t('buttons.Discard')}
                  </KudoButton>
                  <KudoButton
                    variant="primary"
                    disabled={isSubmitting || !email}
                    type="submit"
                    className={styles.inputButton}
                  >
                    {isSubmitting ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      <>{doesProfileExist ? t('buttons.SaveChanges') : t('buttons.InviteUser')}</>
                    )}
                  </KudoButton>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default Users;
